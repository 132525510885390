import { 
        GET_READING_COMPREHENSIONS, 
        GET_READING_COMPREHENSIONS_SUCCESS,
        SET_LOADING_READING_COMPREHENSIONS,
        SET_LOADING,
        SET_SELECTIONS_SELECTED
} from '../constants'

export const setSelectionLoading = (payload) => {
    return {
        type: SET_LOADING,
        payload,
    }
}

export const getReadingComprehensions = (payload) => {
    return {
        type: GET_READING_COMPREHENSIONS,
        payload
    }
}

export const getReadingComprehensionsSuccess = (payload) => {
    return {
        type: GET_READING_COMPREHENSIONS_SUCCESS,
        payload
    }
}

export const setLoading = (payload) => {
    return {
        type: SET_LOADING_READING_COMPREHENSIONS,
        payload
    }
}

export const setSelectionsSelected = (payload) => {
    return {
        type: SET_SELECTIONS_SELECTED,
        payload,
    }
}