import {
    CHANGE_GAME_SETTINGS,
    SET_GAME_SETTINGS,
    RESET_GAME_SETTINGS,
} from '../constants'

export const setGameSettings = (payload) => {
    console.log('payload', payload)
    return {
        type: SET_GAME_SETTINGS,
        payload,
    }
}

export const changeGameSettings = (payload) => {
    return {
        type: CHANGE_GAME_SETTINGS,
        payload,
    }
}

export const resetGameSettings = () => ({
    type: RESET_GAME_SETTINGS,
})
