import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import AvatarChild from '../../../common/AvatarChild/AvatarChild'
import { changeGameSettings } from '../../../../store/settingSec/actions'
import './styles.scss'

const optionsData = [
    {
        title: 'Audio for Phrase',
        key: 'audioForPhrase',
    },
    {
        title: 'Audio for Answer',
        key: 'audioForAnswer',
    },
    {
        title: 'Audio for Correct Answer',
        key: 'audioForCorrectAnswer',
    },
    {
        title: 'Increase Level Prompt',
        key: 'increaseLevelPrompt',
    },
    {
        title: 'Auto Progression',
        key: 'autoProgression',
    },
]

const Index = ({ handleClose }) => {
    const modalRef = useRef()
    const dispatch = useDispatch()
    const childSelected = useSelector(
        (state) => state.gamesSelection.childSelected
    )
    const { preposition } = useSelector(
        (state) => state.settingSec.gameSettingsData
    )
    const [settingOptions, setSettingOptions] = useState(null)

    useEffect(() => {
        if (preposition) {
            setSettingOptions(preposition)
        }
    }, [preposition])

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                modalRef.current &&
                event.target.className === 'preposition-settings'
            ) {
                handleClose()
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [modalRef])

    const handleChange = (key) => {
        setSettingOptions({ ...settingOptions, [key]: !settingOptions[key] })
    }

    const handleResetSettings = async () => {
        await dispatch(
            changeGameSettings({
                currentGame: 'preposition',
                gameSettingValues: settingOptions,
            })
        )
        await handleClose()
    }

    return (
        <div
            className={`preposition-settings modal fade show d-block`}
            ref={modalRef}
        >
            <div className="modal-dialog modal-dialog-2 modal-dialog-centered">
                <div className="modal-content border-0">
                    <div className="modal-header text-center">
                        <h5
                            className="modal-title md-c1"
                            id="exampleModalLabel"
                        >
                            Settings
                        </h5>
                        <button
                            type="button"
                            className="close"
                            onClick={() => handleResetSettings()}
                        >
                            <span>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 py-0">
                        <div className="user-1">
                            <AvatarChild avatar={childSelected?.avatar} />
                            <span>{childSelected?.studentName}</span>
                        </div>
                        <div className="px-4">
                            <div className="divider-1"></div>
                        </div>
                        <div className="modal-w">
                            <div className="adjective-modal">
                                {settingOptions &&
                                    optionsData.map((data) => (
                                        <div
                                            className="circular-div"
                                            key={data.key}
                                        >
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="category-name">
                                                    {data.title}
                                                </div>
                                                <div>
                                                    <label
                                                        className={`switch ${settingOptions[data.key] ? `checked` : ''}`}
                                                        onClick={() =>
                                                            handleChange(
                                                                data.key
                                                            )
                                                        }
                                                    ></label>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index