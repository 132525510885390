import { all, spawn } from 'redux-saga/effects'
import authSaga from './auth/saga'
import childrenSaga from './children/saga'
import gamesSelectionSaga from './gamesSelection/saga'
import storiesSaga from './stories/saga'
import readingComprehensionsSaga from './readingComprehensions/saga'
import settingSec from './settingSec/saga'
import soundWindow from './soundWindow/saga'
import wordsFarm from './wordsFarm/saga'
import socialQuestSaga from './socialQuest/saga'
import adjectiveRemix from './adjectiveRemix/saga'
import preposition from './preposition/saga'

export default function* saga() {
    yield all([
        spawn(authSaga),
        spawn(childrenSaga),
        spawn(gamesSelectionSaga),
        spawn(storiesSaga),
        spawn(readingComprehensionsSaga),
        spawn(settingSec),
        spawn(soundWindow),
        spawn(wordsFarm),
        spawn(socialQuestSaga),
        spawn(adjectiveRemix),
        spawn(preposition)
    ])
}
