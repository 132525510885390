import { put, select } from 'redux-saga/effects'
import { selectGameAPIData, selectBakeryAPIData } from '../selectors'
import * as actions from '../actions'
import { randomizeArray } from '../../../utils/util';


export function* getBakeryData(payload, getGameData) {
    const { from, selectedSyllabes, screens } = payload;
    let bakeryState = yield select(selectGameAPIData)


    if (!bakeryState) {
        const bakeryData = yield* getGameData(
            null,
            null,
            null,
            'BAKERY',
            from,
            null,
            null
        )
        const parkData = yield* getGameData(
            null,
            null,
            null,
            'PARK',
            from,
            null,
            null
        )
        bakeryState = { bakeryData: bakeryData.questions, parkData: parkData.questions };

        yield put(actions.setGameData({ apiData: bakeryState }))

    }
    let resultItems = [];

    bakeryState.bakeryData.forEach((item) => {
        let answerData = [];
        let correctAnswer = { id: item.id + '1', isVisible: true, word: item.correct }

        answerData.push(correctAnswer)
        for (let i = 1; i <= screens; i++) {
            answerData.push(
                { id: item.id + i, isVisible: true, word: item[`level${i}`] },
            )

        }
        resultItems.push({
            id: item.id,
            imagePath: item.image_path,
            image: item.image,
            answers: randomizeArray(answerData),
            sentence: item.question,
            correct: item.correct,
            sound: item.sound,
            sound_path: item.sound_path
        })

    })

    bakeryState.parkData.forEach((item) => {
        let answerData = [];
        let correctAnswer = { id: item.id + '1', isVisible: true, word: item.correct }

        answerData.push(correctAnswer)

        answerData.push(
            { id: item.id, isVisible: true, word: item.foil },
        )
        resultItems.push({
            id: item.id,
            imagePath: item.image_path,
            image: item.image,
            answers: randomizeArray(answerData),
            sentence: item.question,
            correct: item.correct,
            sound: item.sound,
            sound_path: item.sound_path
        })

    })
    if (selectedSyllabes.length === 1 && selectedSyllabes.includes(1)) {
        resultItems = resultItems.filter((item) => item.answers.length === 4 || item.answers.length === 3)
    } else if (selectedSyllabes.length === 1 && selectedSyllabes.includes(2)) {

        resultItems = resultItems.filter((item) => item.answers.length == 2)
    }
    const displayData = randomizeArray(resultItems)
    const metaFieldData = {
        activeItemIndex: 0,
        gameData: displayData,
        droppedAnswer: [],
        invisibleAnswerIds: [],
        questionsAnswered: [],
        activeAnswers: displayData[0].answers,
        correctAnswerCount: 0,
        activeType: 'receptive'
    }
    return { displayData, metaFieldData }
}