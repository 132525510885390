import { select } from 'redux-saga/effects'
import { randomizeArray } from '../../../utils/util';
import { selectAdjectiveRemixState } from '../../adjectiveRemix/selectors';
import { selectDitmState } from '../../ditm/selectors';

export function* getDitmData(payload, getGameData) {
    const {
        languageSelected,
        from,
    } = payload
    let ditmState = yield select(selectDitmState)
    let resultItems = []
    if (!ditmState) {
        ditmState = yield* getGameData(
            null,
            null,
            null,
            'DITM',
            from,
            null,
            languageSelected
        )
    }

    resultItems = ditmState
    let displayData;
    displayData = randomizeArray(resultItems)
    const metaFieldData = {
        activeItemIndex: 0,
        categoryIndex: 0,
        gameType: 1,
        languageSelected
    }

    return { displayData, metaFieldData }

};
