import React, { Fragment } from 'react'
import { useLocation } from 'react-router-dom'
import Header from './Header'

const Index = (props) => {
    const location = useLocation()
    return (
        <Fragment>
            {!location.pathname.includes('activity') && <Header />}
            {props.children}
        </Fragment>
    )
}

export default Index
