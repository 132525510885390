import { put, select } from "redux-saga/effects";
import { selectGameAPIData } from "../selectors";
import * as actions from "../actions";
import { randomizeArray } from "../../../utils/util";

export function* getPictureMatching(payload, getPictureMatchingData) {
    const { from, unSelectedCategories, screens } = payload;
    let newPictureMatchingState = yield select(selectGameAPIData);
    const childLanguage = payload.childSelected.language
    
    let questionsLang = []
    console.log("questionsLang", questionsLang)
    if (!newPictureMatchingState) {
        newPictureMatchingState = yield* getPictureMatchingData(
            null,
            null,
            null,
            "PICTUREMATCHING",
            from,
            null,
            null
        );
        const enCategories = []
        const ptCategories = []
        const spCategories = []
        console.log("payload", payload)
        // const generateQuestions = () => {
        //     if(childLanguage === "en") return newPictureMatchingState.questions_en
        //     if(childLanguage === "pt") return newPictureMatchingState.questions_pt
        //     if(childLanguage === "sp") return newPictureMatchingState.questions_sp
        // }
        // questionsLang = generateQuestions()
        const enCategory = newPictureMatchingState.questions_en.map((item) => {
            let cate = item.category;
            if (!enCategories.includes(cate)) {
                enCategories.push(cate);
            }
        })
        const ptCategory = newPictureMatchingState.questions_pt.map((item) => {
            let cate = item.category;
            if (!ptCategories.includes(cate)) {
                ptCategories.push(cate);
            }
        })
        const spCategory = newPictureMatchingState.questions_sp.map((item) => {
            let cate = item.category;
            if (!spCategories.includes(cate)) {
                spCategories.push(cate);
            }
        })
        yield put(actions.setGameData({ apiData: newPictureMatchingState, categoryData: {en:enCategories, sp: spCategories, pt: ptCategories } }));
    }
        const generateQuestions = () => {
            if(childLanguage === "en") return newPictureMatchingState.questions_en
            if(childLanguage === "pt") return newPictureMatchingState.questions_pt
            if(childLanguage === "sp") return newPictureMatchingState.questions_sp
        }
        questionsLang = generateQuestions()
    
    const resultItems = [];

    

    questionsLang.map((item, idx) => {
        let answerData = [];
        let correctAnswer = {
            id: item.answers[0].id,
            image: item.answers[0].image,
            is_correct: item.answers[0].is_correct,
            answerAudio: item.answers[0].audio
        };

        answerData.push(correctAnswer);
        for (let i = 1; i < screens; i++) {
            answerData.push({
                id: item.answers[i].id + i,
                image: item.answers[i].image,
                is_correct: item.answers[i].is_correct,
                answerAudio: item.answers[i].audio

            });
            if (i === 0) {
                answerData.pop()
            }
        }
        if (!unSelectedCategories.includes(item.category)) {
            resultItems.push({
                id: item.id,
                answers: randomizeArray(answerData),
                text: item.text,
                correct: item.answers[0].id,
                questionId: item.id,
                imagePath: questionsLang.image_files_path,
                image: item.image,
                audioPath: questionsLang.audio_files_path,
                categoryName: item.category,
                audio: item.audio
            })
        }
    });


    const displayData = randomizeArray(resultItems);
    const metaFieldData = {
        activeSlide: 0,
        gameData: displayData,
        droppedAnswer: [],
        questionsAnswered: [],
        activeAnswers: displayData[0].answers,
        correctAnswerCount: 0,
    };
    return { displayData, metaFieldData };
}
