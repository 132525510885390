import { put, select } from 'redux-saga/effects'
import { selectGameAPIData } from '../selectors'
import * as actions from '../actions'
import { randomizeArray } from '../../../utils/util';


export function* getPronounHeroesData(payload, getGameData) {
    const { from } = payload;

    let heroesState = yield select(selectGameAPIData)

    if (!heroesState) {
        heroesState = yield* getGameData(
            null,
            null,
            null,
            'PRONOUN_FINDER',
            from,
            null,
            null
        )
        yield put(actions.setGameData({ apiData: heroesState }))
    }


    const wordData = heroesState.filter((item) => item.type === 'words')
    const phraseData = heroesState.filter((item) => item.type === 'phrases')

    let resultItems = [];

    wordData.map((item, idx) => {
        let answerData = [];
        let targetData = { id: item.id + 't', target: item.target, word: 'target' }

        let correctAnswer = { id: item.id + '1', isVisible: true, word: item.correct, answerSyllable: item.correct }

        answerData.push(correctAnswer)
        for (let i = 1; i < 4; i++) {
            answerData.push(
                { id: item.id + i, isVisible: true, word: item[`wrong${i}`], answerSyllable: item[`wrong${i}`] },
            )
        }

        return (
            resultItems.push({
                id: item.id,
                audioPath: item.audio_path,
                imagePath: item.image_path,
                targetData: targetData,
                currentItemSyllable: item.correct,
                answers: randomizeArray(answerData)
            })
        )
    })

    let phraseItems = [];
    phraseData.map((item, idx) => {
        const phrase = item.phrase.split(" ")
        let answerData = [];
        let targetData = { id: item.id + 'c', target: item.correct, word: 'correct' }

        for (let i = 0; i < phrase.length; i++) {
            answerData.push(
                { id: item.id + i, isVisible: true, word: phrase[i], answerSyllable: phrase[i] },
            )
        }

        return (
            phraseItems.push({
                id: item.id,
                targetData: targetData,
                currentItemSyllable: item.correct,
                answers: answerData
            })
        )
    })

    const displayData = randomizeArray(resultItems)
    const metaFieldData = {
        activeItemIndex: 0,
        gameData: displayData,
        droppedAnswer: [],
        invisibleAnswerIds: [],
        questionsAnswered: [],
        activeAnswers: displayData[0].answers,
        correctAnswerCount: 0,
        wordCount: 0,
        phraseCount: 0
    }
    return { displayData, metaFieldData, phraseItems }
}