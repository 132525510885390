import React, { useState } from 'react';
import './styles.scss'

const BrowserNotSupportedModal = ({ isEdge }) => {
    const [open, setOpen] = useState(true)
    return (
        <div className={`BrowserNotSupportedModal ${!!open ? 'show' : 'hide'}`}>
            <div className={`center ${!!open ? 'show' : 'hide'}`}>
                <div className="d-flex justify-content-center flex-column align-items-center p-4">
                    <i className="fa fa-exclamation-triangle alert-icon" aria-hidden="true"></i>
                    <p className="mb-0"> <b><big>Warning!</big></b> <br />
                        We've detected you are running {isEdge ? 'Microsoft Edge' : 'Internet Explorer'}. Please note that this application was developed to run best on Chrome or Safari. We recommend you switch browsers for the best user experience.
                    </p>
                </div>
                <button onClick={() => setOpen(false)}>Continue</button>
            </div>
        </div>
    )
}

export default BrowserNotSupportedModal
