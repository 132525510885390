//Example of Audio
export const AUDIO_URL =
    'https://www.freesound.org/data/previews/338/338825_1648170-lq.mp3'

// S3 files root url
// export const S3_ROOT_URL =
//     'https://sla-data-speechlanguageacademy.s3.amazonaws.com' OLD ROUTE
export const S3_ROOT_URL =
    'https://sla-data-speechlanguageacademy.s3.amazonaws.com'

//URL OF THERAPIST PROFILE
export const THERAPIST_PROFILE_FOLDER = `${S3_ROOT_URL}/`

//URL OF FLASHCARD
export const Files_Path = `${S3_ROOT_URL}/activities/articulation/stimulus/flashcard/photographs/`
export const FLASHCARD_PHOTOGRAPHS = `${S3_ROOT_URL}/activities/articulation/stimulus/flashcard/photographs/`
export const FLASHCARD_VIDEOS = `${S3_ROOT_URL}/activities/articulation/videos/`
export const FLASHCARD_AUDIOS = `${S3_ROOT_URL}/activities/articulation/stimulus/flashcard/audio/`

export const S3_READING_COMPREHENSIONS_IMAGES = `${S3_ROOT_URL}/activities/Reading/readingcomprehensioncamp/Images/`
export const S3_READING_COMPREHENSIONS_SOUNDS = `${S3_ROOT_URL}/activities/Reading/readingcomprehensioncamp/Audios/`
export const S3_READING_COMPREHENSIONS_THUMBNAILS = `${S3_ROOT_URL}/activities/Reading/readingcomprehensioncamp/ThumbImages/`

export const S3_SOUND_WINDOW_CONSONANT_IMAGES = `${S3_ROOT_URL}/activities/articulation/stimulus/soundwindows/consonant/`
export const S3_SOUND_WINDOW_VOWEL_IMAGES = `${S3_ROOT_URL}/activities/articulation/stimulus/soundwindows/vowel/`
export const S3_SOUND_WINDOW_SOUNDS = `${S3_ROOT_URL}/activities/articulation/stimulus/soundwindows/SoundWindowsAudios/`

export const S3_STORY_AUDIO = `${S3_ROOT_URL}/activities/articulation/stimulus/stories/StoryAudio/`

export const S3_WORDS_FARM_IMAGES = `${S3_ROOT_URL}/activities/smartysymbols/`
export const S3_WORDS_FARM_SOUNDS = `${S3_ROOT_URL}/activities/articulation/stimulus/apraxiafarm/Audios/`

export const FAF_IMAGES = `${S3_ROOT_URL}/activities/language/funandfunctional/WordsData/`
export const XRAY_IMAGES = `${S3_ROOT_URL}/activities/smartysymbols/`
export const S3_DITM_IMAGES = `${S3_ROOT_URL}/activities/language/ditm/Images/`
export const S3_DITM_SOUNDS = `${S3_ROOT_URL}/activities/language/ditm/Audios/`

export const S3_SOCIAL_QUEST_IMAGES = `${S3_ROOT_URL}/activities/language/socialquest/Images/`

export const S3_GOTOGETHER_IMAGES = `${S3_ROOT_URL}/activities/language/gotogethers/CategoryImages/`
export const S3_GOTOGETHER_SOUNDS = `${S3_ROOT_URL}/activities/language/gotogethers/CategoryAudios/`

export const S3_ADJECTIVE_REMIX_IMAGES = `${S3_ROOT_URL}/activities/language/adjectiveremix/Images/`
export const S3_ADJECTIVE_REMIX_SOUNDS = `${S3_ROOT_URL}/activities/language/adjectiveremix/Audio`

export const S3_FOLLOWING_DIRECTIONS_AUDIOS = `${S3_ROOT_URL}/activities/language/FDM/Audios/`
export const S3_FOLLOWING_DIRECTIONS_IMAGES = `${S3_ROOT_URL}/activities/language/FDM`

export const S3_PREPOSITION_IMAGES = `${S3_ROOT_URL}/activities/language/preppositions/Images/`
export const S3_PREPOSITION_AUDIOS = `${S3_ROOT_URL}/activities/language/preppositions/Audios/`

export const S3_FIND_IMAGES = `${S3_ROOT_URL}/activities/articulation/stimulus/flashcard/photographs/`
export const S3_PICTURE_MATCHING = `${S3_ROOT_URL}/activities/language/languagetrainer/ActivityImages/`
