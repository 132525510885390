import { put, select } from "redux-saga/effects";
import { selectGameAPIData } from "../selectors";
import * as actions from "../actions";
import { randomizeArray } from "../../../utils/util";

export function* getBeginningSounds(payload, getPictureMatchingData) {
    const { from, screens } = payload;
    let newBeginningSoundsState = yield select(selectGameAPIData);
    if (!newBeginningSoundsState) {
        newBeginningSoundsState = yield* getPictureMatchingData(
            null,
            null,
            null,
            "BEGINNINGSOUNDS",
            from,
            null,
            null
        );

        yield put(actions.setGameData({ apiData: newBeginningSoundsState }));
    }
    const resultItems = [];

    newBeginningSoundsState.questions_en.map((item, idx) => {
        let answerData = [];
        let correctAnswer = {
            id: item.answers[0].id,
            image: item.answers[0].image,
            is_correct: item.answers[0].is_correct,
            answerAudio: item.answers[0].audio
        };

        answerData.push(correctAnswer);
        for (let i = 1; i < screens; i++) {
            answerData.push({
                id: item.answers[i].id + i,
                image: item.answers[i].image,
                is_correct: item.answers[i].is_correct,
                answerAudio: item.answers[i].audio

            });
            if (i === 0) {
                answerData.pop()
            }
        }

        resultItems.push({
            id: item.id,
            answers: randomizeArray(answerData),
            text: item.text,
            correct: item.answers[0].id,
            questionId: item.id,
            imagePath: newBeginningSoundsState.image_files_path,
            image: item.image,
            audioPath: newBeginningSoundsState.audio_files_path,
            audio: item.audio
        })
    });


    const displayData = randomizeArray(resultItems);
    const metaFieldData = {
        activeSlide: 0,
        gameData: displayData,
        questionsAnswered: [],
        activeAnswers: displayData[0].answers,
        receptiveCorrectAnswerCount: 0,
        expressiveCorrectAnswerCount: 0,
    };
    return { displayData, metaFieldData };

}