import { BASE_URL } from './environment'

export const URLS = {
    THERAPIST: { url: 'therapist', subUrl: '' },
    THERAPIST_STUDENTS: { url: 'therapist', subUrl: 'students' },
    STUDENTS: { url: 'auth', subUrl: 'user' },
    APP_CONTENT: { url: 'games', subUrl: 'flashcards' },
    GUESS_WHAT: { url: 'games', subUrl: 'guess-whats' },
    STORY_CONTENT: { url: 'games', subUrl: 'stories' },
    GRADES: { url: 'get-grades', subUrl: '' },
    SESSION_STORE: { url: 'session-store', subUrl: '' },
    READING_COMPREHENSIONS: { url: 'games', subUrl: 'camps' },
    WH_QUESTIONS: { url: 'games', subUrl: 'wh-questions' },
    POST_GAME_STATUS: { url: 'games', subUrl: 'current-status' },
    CREATE_NOTE: { url: 'student', subUrl: 'student/note/create' },
    SYLLABLE_SPLASH: { url: 'games', subUrl: 'syllable-splashs' },
    TALKIE_TOT: { url: 'games', subUrl: 'talkie-tots' },
    WORDS_FARM: { url: 'games', subUrl: 'apraxiafarms' },
    SOUND_WINDOW: { url: 'games', subUrl: 'windows-sounds' },
    FUN_FUNCTIONAL: { url: 'games', subUrl: 'faf-games' },
    DITM: { url: 'games', subUrl: 'ditms' },
    SOCIAL_QUEST: { url: 'games', subUrl: 'quest-games' },
    GO_TOGETHER: { url: 'games', subUrl: 'go-togethers' },
    ADJECTIVE_REMIX: { url: 'games', subUrl: 'adjective-remixes' },
    FOLLOWING_DIRECTIONS: { url: 'games', subUrl: 'following-directions' },
    PREPOSITION: { url: 'games', subUrl: 'prepositions' },
    VERB_QUEST: { url: 'games', subUrl: 'verb-quests' },
    IPRACTICE_VERBS: { url: 'games', subUrl: 'i-practice-verbs' },
    IS_THAT_SILLY: { url: 'games', subUrl: 'is-that-silly' },
    YES_NO_BARN: { url: 'games', subUrl: 'yes-no-barn' },
    TEXT_IMAGE_MATCH: { url: 'games', subUrl: 'images-match-game' },
    IMAGE_TEXT_MATCH: { url: 'games', subUrl: 'match-images-game' },
    READ_AND_ANSWER: { url: 'games', subUrl: 'read-answer' },
    PHRASE_BUILDER: { url: 'games', subUrl: 'phrase-building' },
    PICTURE_IDENTIFICATION: { url: 'games', subUrl: 'picture-identification' },
    SENTENCE_COMPLETION: { url: 'games', subUrl: 'sentence-completion' },
    PICTURE_NAMING: { url: 'admin', subUrl: 'games/picture-naming' },
    DIVERGENT_NAMING: { url: 'games', subUrl: 'divergent-naming' },
    INFERENCING: { url: 'games', subUrl: 'language-empires-inferencing' },
    FIGURATIVE: {
        url: 'games',
        subUrl: 'language-empires-figurative-language',
    },
    WHICH: { url: 'games', subUrl: 'language-empires-which' },
    XRAY_PHONO: {
        url: 'games',
        subUrl: 'phonological-awareness-lab-syllablexray',
    },
    RHYMING_SELECTION: {
        url: 'games',
        subUrl: 'phonological-awareness-lab-rhyming?game=RhymingSelection',
    },
    RHYMING_MATCH: {
        url: 'games',
        subUrl: 'phonological-awareness-lab-rhyming?game=RhymingMatch',
    },
    RHYMING_IDENTIFICATION: {
        url: 'games',
        subUrl: 'phonological-awareness-lab-rhyming-identification',
    },
    SEQUENCING: { url: 'games', subUrl: 'language-empires-sequencing' },
    HOW: { url: 'games', subUrl: 'language-empires-how' },
    PREDICTING: { url: 'games', subUrl: 'language-empires-predicting' },
    VOCABULARY: { url: 'games', subUrl: 'language-empires-vocabulary' },
    DECODINGROOM: {
        url: 'games',
        subUrl: 'phonological-awareness-lab-blending',
    },
    CUSTOMER_SUPPORT: { url: 'therapist', subUrl: 'connect-support' },
    SENTENCEEXPERIMENT: {
        url: 'games',
        subUrl: 'phonological-awareness-lab-sentence-experiment',
    },
    WHICHISNOTPRONOUN: {
        url: 'games',
        subUrl: 'pronoun-heroes-which-not-pronoun',
    },
    PRONOUN_FINDER: { url: 'games', subUrl: 'pronoun-heroes-where-pronoun' },
    FINDTHEPICTURE: { url: 'games', subUrl: 'pronoun-heroes-find-the-picture' },
    USETHEPRONOUN: { url: 'games', subUrl: 'pronoun-heroes-lets-use-pronouns' },
    BAKERY: { url: 'games', subUrl: 'syntax-city-bakery' },
    GRAMMARSKI: { url: 'games', subUrl: 'syntax-city-ski-resorts' },
    PARK: { url: 'games', subUrl: 'syntax-city-park' },
    FARM: { url: 'games', subUrl: 'syntax-city-farm' },
    BEACH: { url: 'games', subUrl: 'syntax-city-beach' },
    ZOO: { url: 'games', subUrl: 'syntax-city-zoo' },
    GYM: { url: 'games', subUrl: 'syntax-city-gym' },
    STORE: { url: 'games', subUrl: 'syntax-city-grocery-store' },
    LETTER_NAMES: { url: 'game', subUrl: 'letter-names' },
    WOULD_YOU_RATHER: { url: 'admin', subUrl: 'game/wouldyourather_en' },
    PICTUREMATCHING: { url: 'admin', subUrl: 'game/picturematching' },
    FINDIMAGE: { url: 'admin', subUrl: 'game/which-one-does-not-belong' },
    LEARNINGABOUTYOU: { url: 'admin', subUrl: 'game/learning-about-you' },
    WHEREDOESITGO: { url: 'admin', subUrl: 'game/where-does-it-go' },
    BEGINNINGSOUNDS: { url: 'admin', subUrl: 'game/beginning-sounds' },
    //ALLGAMES: { url: "all-games", subUrl: null }, STAGING
    ALLGAMES: { url: 'admin', subUrl: 'all-games' },
    GAMECATEGORIES: { url: 'admin', subUrl: 'game-tags' },
    SMARTORALMOTOR: { url: 'admin', subUrl: 'game/smart-oral-motor' },
    SORTING: { url: 'admin', subUrl: 'game/sorting' },
    CATEGORYNAMING: { url: 'admin', subUrl: 'game/category-naming' },
    GAMELANGUAGES: { url: 'admin', subUrl: 'game-languages' },
    MINIMALPAIRSACADEMY: { url: 'admin', subUrl: 'game/minimal-pairs-academy' },
}
export const getApiUrl = (endpoint) => BASE_URL + endpoint
export const CREATE_NOTE_USER = getApiUrl('student/note/create')
export const NOTIFICATIONS = 'notifications' //getApiUrl("notifications");

export const getEndpointURL = (key) => {
    let finalUrl = ''
    if (key && URLS[key]) {
        const urlKey = URLS[key]
        if (urlKey.url) {
            finalUrl = urlKey.url
        }
        if (urlKey.subUrl && urlKey.subUrl.length > 0) {
            finalUrl += `/${urlKey.subUrl}`
        }
    }
    return finalUrl
}
