import { SET_GAME_SETTINGS, RESET_GAME_SETTINGS } from '../constants'

export const initialState = {
    gameSettingsData: {
        'flash-card': {
            transitionSound: false,
            displayWrittenWord: true,
            moveAutomatically: false,
        },
        matching: {
            displayWrittenWord: true,
            displayAnimation: true,
        },
        'guess-what': {
            displayWrittenWord: true,
            displayAnimation: true,
        },
        talkieTot: {
            speakPhraseBefore: true,
            colorShapeCue: true,
            visualEffect: true,
            automaticAdvance: true,
        },
        'syllable-splash': {
            numberOfWrongAnswer: 4,
            increaseLevel: true,
        },
        ditm: {
            language: true,
        },
        preposition: {
            audioForPhrase: true,
            audioForAnswer: true,
            audioForCorrectAnswer: true,
            autoProgression: true,
            increaseLevelPrompt: true,
        },
        speechGames: {
            speakWordAutomatically: true,
        },
    },
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_GAME_SETTINGS: {
            return {
                ...state,
                gameSettingsData: action.payload,
            }
        }
        case RESET_GAME_SETTINGS:
            return initialState

        default:
            return state
    }
}
