import React, { useEffect, useState } from 'react'
import Draggable from 'react-draggable'
import { useDispatch, useSelector } from 'react-redux'
import { BASE_URL } from '../../../utils/environment'
import GET_API_KEY from '../../../utils/getApiKey'
import { WellDonePopup } from './WellDonePopup'

export const TimeTracker = ({ sessionId }) => {
    const { timeTrackerData } = useSelector((state) => state.timeTracker)
    const [playingDuration, setPlayingDuration] = useState(0)
    const [timerId, setTimerId] = useState(0)
    const [seconds, setSeconds] = useState(0)
    const [tenSeconds, setTenSeconds] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [tenMinutes, setTenMinutes] = useState(0)
    const [intervalId, setIntervalId] = useState(0)
    const [reqMinutes, setReqMinutes] = useState(0)
    const [reqSeconds, setReqSeconds] = useState(0)
    const [timerCounting, setTimerCounting] = useState(false)
    const [intervalProgressBar, setIntervalProgressBar] = useState(0)
    const [barPlayingDuration, setBarPlayingDuration] = useState(0)
    const [showWellDonePopup, setShowWellDonePopup] = useState(false)
    useEffect(() => {
        console.log('timercoun', timerCounting)
    }, [timerCounting])
    useEffect(() => {
        if (seconds > 9) {
            setTenSeconds((prev) => prev + 1)
            setSeconds(0)
        }
        if (tenSeconds > 5) {
            setMinutes((prev) => prev + 1)
            setTenSeconds(0)
        }
        if (minutes > 9) {
            setTenMinutes((prev) => prev + 1)
            setMinutes(0)
        }
    }, [seconds])
    const timer = () => {
        setSeconds((prev) => prev + 1)
    }

    const startTimer = () => {
        const interval = setInterval(() => {
            setPlayingDuration((prev) => prev + 1)
        }, 1000 * 30)

        const intervalProgressBar = setInterval(() => {
            setBarPlayingDuration((prev) => prev + 1)
        }, 1000)
        setTimerId(interval)
        setIntervalProgressBar(intervalProgressBar)
    }

    useEffect(() => {
        if (playingDuration === 0) {
            return
        }
        sendTimeData()
    }, [playingDuration])

    const stopTimer = () => {
        console.log('STOP TIMER')
        clearInterval(timerId)
        setTimerCounting(true)
    }

    useEffect(() => {
        if (
            window.location.pathname.indexOf(timeTrackerData.gameName) > 1 &&
            timeTrackerData.remainingDuration > 0
        ) {
            setIntervalId(setInterval(timer, 1000))
            startTimer()
            setRequiredTime()
            setTimerCounting(true)
        } else {
            stopTimer()
            clearInterval(intervalId)
            setTimerCounting(false)
            clearInterval(intervalProgressBar)
        }
    }, [window.location.pathname, timeTrackerData])

    const sendTimeData = () => {
        const axios = require('axios')
        const data = JSON.stringify({
            student_id: timeTrackerData.studentId,
            game_id: timeTrackerData.gameId,
            played_time: 30,
            is_completed:
                playingDuration * 30 >= timeTrackerData.remainingDuration
                    ? 1
                    : 0,
            session_id: sessionId,
            assignment_id: timeTrackerData.assignmentId,
        })
        const apiKey = GET_API_KEY()
        const url = BASE_URL + 'games/record-played-time'
        const config = {
            method: 'post',
            url,
            headers: {
                Authorization: `Bearer ${apiKey}`,
                'Content-Type': 'application/json',
            },
            data: data,
        }

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data))
                if (playingDuration * 30 >= timeTrackerData.remainingDuration) {
                    stopTimer()
                    setTimerCounting(false)
                    setShowWellDonePopup(true)
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }
    const setRequiredTime = () => {
        const minutes = Math.floor(timeTrackerData.remainingDuration / 60)

        setReqMinutes(minutes)
        setReqSeconds(timeTrackerData.remainingDuration - minutes * 60)
    }
    console.log(barPlayingDuration)

    return (
        <>
            {timerCounting && (
                <Draggable
                    position={null}
                    defaultPosition={{ x: 0, y: 0 }}
                    defaultClassName="react-draggable-widget"
                >
                    <div>
                        <div className="stop-watch-widget timer">
                            <div className="text-center">
                                <h2> Required Time </h2>
                            </div>
                            <div className="stop-watch-inner">
                                <div className="counter">
                                    <div id="timer">{`${tenMinutes}${minutes}:${tenSeconds}${seconds} / ${
                                        reqMinutes >= 10 ? '' : '0'
                                    }${reqMinutes}:${
                                        reqSeconds === 0 ? '0' : ''
                                    }${reqSeconds}`}</div>
                                </div>
                            </div>
                            <div className="timer-progress-bar">
                                <div
                                    className="inner"
                                    style={{
                                        width: `${
                                            (barPlayingDuration /
                                                timeTrackerData.remainingDuration) *
                                            100
                                        }%`,
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                </Draggable>
            )}
            {showWellDonePopup && (
                <WellDonePopup setShowWellDonePopup={setShowWellDonePopup} />
            )}
        </>
    )
}
