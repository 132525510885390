import {
    GET_WORDS_FARM_DATA, SET_WORDS_FARM_DATA
} from '../constants';

const getWordsFarmStateAction = () => {
    return {
        type: GET_WORDS_FARM_DATA
    }
}

const setWordsFarmStateAction = (payload) => {
    return {
        type: SET_WORDS_FARM_DATA,
        payload
    }
}

export {
    getWordsFarmStateAction,
    setWordsFarmStateAction
}