import { combineReducers } from 'redux'
import authReducer from './auth/reducers'
import childrenReducer from './children/reducers'
import gamesSelectionReducer from './gamesSelection/reducers'
import storiesReducer from './stories/reducers'
import settingSecReducer from './settingSec/reducers'
import readingComprehensionsReducer from './readingComprehensions/reducers'
import soundWindowReducer from './soundWindow/reducers'
import wordsFarmReducer from './wordsFarm/reducers'
import ditmReducer from './ditm/reducers'
import socialQuestReducer from './socialQuest/reducers'
import adjectiveRemixReducer from './adjectiveRemix/reducers'
import prepositionReducer from './preposition/reducers'
import timeTrackerReducer from './timeTracker/reducers'
import gameFilterReducer from './gameFilter/reducers'

export default combineReducers({
    auth: authReducer,
    children: childrenReducer,
    gamesSelection: gamesSelectionReducer,
    stories: storiesReducer,
    settingSec: settingSecReducer,
    readingComprehensions: readingComprehensionsReducer,
    soundWindow: soundWindowReducer,
    wordsFarm: wordsFarmReducer,
    ditm: ditmReducer,
    socialQuest: socialQuestReducer,
    adjectiveRemix: adjectiveRemixReducer,
    preposition: prepositionReducer,
    timeTracker: timeTrackerReducer,
    gameFilter: gameFilterReducer,
})
