import React from 'react'
import './loader.scss'

const Loader = () => {
    return (
        <div
            className="d-flex justify-content-center align-items-center "
            style={{ paddingTop: '50px' }}
        >
            <div
                className="spinner-border"
                style={{ color: '#29abe1' }}
                role="status"
            >
                <span className="sr-only">Loading...</span>
            </div>
        </div>
        // <div className="loader-spinner">
        //     <img src="/assets/imgs/loader.gif" />
        // </div>
        // <span className="loader-spinner-text">
        //     <img src="/assets/imgs/loader-text.gif" />
        // </span>
    )
}

export default Loader
