import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import './styles.css'
import {
    selectedSettings
} from '../../store/settingSec/selectors'
import {
    changeGameSettings,
} from '../../store/settingSec/actions'
import useOnClickOutside from '../../hooks/use-onclick-outside'

const wrongAnswerOptions = [1, 2, 3, 4]
const SettingsSec = ({
    dispatchChangeGameSettings,
    appSettings,
    handleClose,
}) => {
    const settingRef = useRef();
    // Call hook passing in the ref and a function to call on outside click
    useOnClickOutside(settingRef, () => handleClose())
    const [currentGame, setCurrentGame] = useState('')
    const [gameSettingValues, setGameSettingValues] = useState({})
    const location = useLocation()

    const gameSettingOptions = {
        'flash-card': {
            isTransitionSound: true,
            isDisplayWrittenWord: true,
            isMoveAutomatically: true,
        },
        'matching': {
            isDisplayWrittenWord: true,
            isDisplayAnimation: true,
        },
        'guess-what': {
            isDisplayWrittenWord: true,
            isDisplayAnimation: true,
        },
        'syllable-splash': {
            numberOfWrongAnswer: 4,
            increaseLevel: true,
        },
        'ditm': {
            language: true,//True=English, False: Spanish
        },
        "speechGames" : {
            speakWordAutomatically: true
        }
    }
    const isSpeechGame = () =>{
        if (location.pathname.includes('tic-tac-toe') ||
        location.pathname.includes('hot-air-speech') ||
        location.pathname.includes('speech-puzzle') ||
        location.pathname.includes('speech-balloons') ||
        location.pathname.includes("articulation-basketball")){
        return true
        }
        return false
    }
    useEffect(() => {
        if(isSpeechGame()){
            setCurrentGame("speechGames")
            const settings = appSettings.gameSettingsData["speechGames"];
            setGameSettingValues(settings)
            return
        }
        const currentGameName = location.pathname.split('/')[
            location.pathname.split('/').length - 1
        ]
        setCurrentGame(currentGameName)
        const settings = appSettings.gameSettingsData[currentGameName];
        setGameSettingValues(settings)
    }, [appSettings])

    const handleChangeSetting = (settingName, option = 4) => {
        if (settingName == 'numberOfWrongAnswer') {
            gameSettingValues[settingName] = option
        } else {
            gameSettingValues[settingName] = !gameSettingValues[settingName]
        }
        const newSettingsData = {
            currentGame: currentGame,
            gameSettingValues: gameSettingValues,
        }
        dispatchChangeGameSettings(newSettingsData)
    }

    return (
        <div className="settings-sec" ref={settingRef}>
            <span className="fr"></span>
            {gameSettingOptions[currentGame]?.isTransitionSound && (
                <div className="se-div">
                    <p>Transition sound</p>
                    <div className="">
                        <input
                            type="checkbox"
                            hidden="hidden"
                            id="username"
                            checked={!gameSettingValues?.transitionSound}
                            onChange={() =>
                                handleChangeSetting('transitionSound')
                            }
                        />
                        <label className="switch" htmlFor="username" />
                    </div>
                </div>
            )}
            {gameSettingOptions[currentGame]?.isDisplayWrittenWord && (
                <div className="se-div">
                    <p>Display written word</p>
                    <div className="">
                        <input
                            type="checkbox"
                            hidden="hidden"
                            id="username2"
                            checked={!gameSettingValues?.displayWrittenWord}
                            onChange={() =>
                                handleChangeSetting('displayWrittenWord')
                            }
                        />
                        <label className="switch" htmlFor="username2" />
                    </div>
                </div>
            )}
            {gameSettingOptions[currentGame]?.isMoveAutomatically && (
                <div className="se-div">
                    <p>Move automatically</p>
                    <div className="">
                        <input
                            type="checkbox"
                            hidden="hidden"
                            id="username3"
                            checked={!gameSettingValues?.moveAutomatically}
                            onChange={() =>
                                handleChangeSetting('moveAutomatically')
                            }
                        />
                        <label className="switch" htmlFor="username3" />
                    </div>
                </div>
            )}
            {gameSettingOptions[currentGame]?.isDisplayAnimation && (
                <div className="se-div">
                    <p>Display animation</p>
                    <div className="">
                        <input
                            type="checkbox"
                            hidden="hidden"
                            id="username4"
                            checked={!gameSettingValues?.displayAnimation}
                            onChange={() =>
                                handleChangeSetting('displayAnimation')
                            }
                        />
                        <label className="switch" htmlFor="username4" />
                    </div>
                </div>
            )}
            {gameSettingOptions[currentGame]?.language && (
                <div className="se-div">
                    <p>Language -&gt; {gameSettingValues?.language ? 'English' : 'Spanish'}</p>
                    <div className="">
                        <input
                            type="checkbox"
                            hidden="hidden"
                            id="chkLanguage"
                            checked={!gameSettingValues?.language}
                            onChange={() =>
                                handleChangeSetting('language')
                            }
                        />
                        <label className="switch" htmlFor="chkLanguage" />
                    </div>
                </div>
            )}
            {gameSettingOptions[currentGame]?.numberOfWrongAnswer && (
                <div className="se-div">
                    <p>Number of wrong options </p>
                    <div className="">
                        <ul className="pagination">
                            {wrongAnswerOptions.map((option) => <li key={option} className={`${gameSettingValues.numberOfWrongAnswer == option ? 'active' : ''}`}><a onClick={() => handleChangeSetting('numberOfWrongAnswer', option)}>{option}</a></li>)}
                        </ul>
                    </div>
                </div>)}
            {gameSettingOptions[currentGame]?.increaseLevel && (
                <div className="se-div">
                    <p>Increase level if successful? </p>
                    <div className="">
                        <input
                            type="checkbox"
                            hidden="hidden"
                            id="username2"
                            checked={!gameSettingValues?.increaseLevel}
                            onChange={() =>
                                handleChangeSetting('increaseLevel')
                            }
                        />
                        <label className="switch" htmlFor="username2" />
                    </div>
                </div>)}
                {gameSettingOptions[currentGame]?.speakWordAutomatically && (
                <div className="se-div">
                    <p>Speak Word Automatically </p>
                    <div className="">
                        <input
                            type="checkbox"
                            hidden="hidden"
                            id="username2"
                            checked={!gameSettingValues?.speakWordAutomatically}
                            onChange={() =>
                                handleChangeSetting('speakWordAutomatically')
                            }
                        />
                        <label className="switch" htmlFor="username2" />
                    </div>
                </div>)}
        </div>
    )
}

const mapStateToProps = (state) => ({
    appSettings: selectedSettings(state),
})

const mapDispatchToProps = {
    dispatchChangeGameSettings: changeGameSettings,
}

const SettingSecContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsSec)

export default SettingSecContainer
