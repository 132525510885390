import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as constants from '../constants'
import * as actions from './actions'
import network from '../../utils/network'
import { getEndpointURL } from '../../utils/endpoint'
import GET_API_KEY from '../../utils/getApiKey'
import { randomizeArray } from '../../utils/util'

function* getPrepositionData() {
    const API_KEY = GET_API_KEY()
    try {
        const action = getEndpointURL('PREPOSITION')
        const params = {
            headers: {
                Authorization: 'Bearer ' + API_KEY,
            },
        }
        const { body } = yield call(network.getData, action, params)

        yield put(actions.setPrepositionStateAction(body))
    } catch (error) {
        if (error.message.indexOf("401") >= 0) {
            localStorage.clear()
            window.location.replace(process.env.REACT_APP_PAGE_BACKEND + 'login')
        }
    }
}

export default function* () {
    yield all([takeEvery(constants.GET_PREPOSITION_DATA, getPrepositionData)])
}
