import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as actions from './actions'
import * as constants from '../constants'
import network from '../../utils/network'
import { getEndpointURL } from '../../utils/endpoint'
import GET_API_KEY from "../../utils/getApiKey"

function* getSocialQuestData() {
    const API_KEY = GET_API_KEY()
    try {
        const action = getEndpointURL('SOCIAL_QUEST')
        const params = {
            headers: {
                Authorization: 'Bearer ' + API_KEY,
            }
        }
        const { body } = yield call(network.getData, action, params);

        yield put(actions.setSocialQuestStateAction(body))
    } catch (error) {
        if (error.message.indexOf("401") >= 0) {
            localStorage.clear()
            window.location.replace(process.env.REACT_APP_PAGE_BACKEND + 'login')
        }
    }

}

export default function* () {
    yield all([
        takeEvery(constants.GET_SOCIAL_QUEST_DATA, getSocialQuestData),
    ])
}