import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeGameSettings } from '../../../store/settingSec/actions'
import './styles.scss'

const optionsData = [{
 title: "Speak phrase before",
 key: "speakPhraseBefore"
},
{
 title: "Color shape cue",
 key: "colorShapeCue"
},
{
 title: "Visual effect",
 key: "visualEffect"
},
{
 title: "Automatic advance",
 key: "automaticAdvance"
}]

const Index = ({ handleClose }) => {
 const modalRef = useRef()
 const dispatch = useDispatch()
 const { talkieTot } = useSelector(state => state.settingSec.gameSettingsData)
 const [settingOptions, setSettingOptions] = useState(null)

 useEffect(() => {
  if (talkieTot) {
   setSettingOptions(talkieTot)
  }
 }, [talkieTot])

 useEffect(() => {
  function handleClickOutside(event) {
   if (modalRef.current && event.target.className ==='setting-popup') {
    handleClose()
   }
  }

  // Bind the event listener
  document.addEventListener("mousedown", handleClickOutside);
  return () => {
   // Unbind the event listener on clean up
   document.removeEventListener("mousedown", handleClickOutside);
  };
 }, [modalRef]);


 const handleChange = (key) => {
  setSettingOptions({ ...settingOptions, [key]: !settingOptions[key] })
 }

 const handleResetSettings = async () => {
  await dispatch(changeGameSettings({ currentGame: 'talkieTot', gameSettingValues: settingOptions }))
  await handleClose()
 }

 return (
  <div className="setting-popup" ref={modalRef}>
   <div className="setting-box">
    <img src="/assets/imgs/talkieTot/lvl-setting-bg.png" alt="" className="setting-bg" />
    <div className="setting-box">
     {settingOptions && optionsData.map((data) => (
      <div className="se-div" key={data.key}>
       <p>{data.title}</p>
       <div className="">
        <input type="checkbox" hidden="hidden" id={data.key} checked={settingOptions[data.key]} onChange={() => handleChange(data.key)} />
        <label className="switch" for={data.key}></label>
       </div>
      </div>
     ))}
     <a onClick={handleResetSettings} className="btn btn-seting-reset">Save</a>
    </div>
   </div>
  </div>
 );
};

export default Index;