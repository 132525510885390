import { GET_GAME_FILTER, SET_GAME_FILTER } from '../constants'

export const setGameFilter = (payload) => {
    console.log('payload', payload)
    return {
        type: SET_GAME_FILTER,
        payload,
    }
}
export const getGameFilter = (payload) => {
    console.log('payload', payload)
    return {
        type: GET_GAME_FILTER,
        payload,
    }
}
