import { select, put } from 'redux-saga/effects'
import { randomizeArray } from '../../../utils/util';
import { selectAdjectiveRemixState } from '../../adjectiveRemix/selectors';
import { selectGameAPIData } from '../selectors'
import * as actions from '../actions'
export function* getAdjectiveRemixData(payload, getGameData) {
    const {
        unSelectedItems,
        presentation,
        language,
        from,
        previousLanguage
    } = payload
    let adjectiveRemixState = yield select(selectGameAPIData)
    const resultItems = []
    if (!adjectiveRemixState || language !== previousLanguage) {
        adjectiveRemixState = yield* getGameData(
            null,
            null,
            null,
            'ADJECTIVE_REMIX',
            from,
            null,
            language
        )
        yield put(actions.setGameData({ apiData: adjectiveRemixState }))
    }
    adjectiveRemixState.map((category) => {
        category.areas.map((area) => {
            if (
                !unSelectedItems[category.name] ||
                !unSelectedItems[category.name].includes(area.area)
            ) {
                area.items.map((item, id) => {
                    resultItems.push({
                        categoryName: category.name,
                        areaName: area.area,
                        question: item.question,
                        questionId: item.id,
                        questionAudio: item.audio,
                        correct: item.correct,
                        answers: randomizeArray([
                            {
                                id: id + '1',
                                isAnswered: false,
                                answerText: item.correct,
                                answerAudio: item.audio,
                            },
                            {
                                id: id + '2',
                                isAnswered: false,
                                answerText: item.wrong,
                                answerAudio: item.audio,
                            },
                        ]),
                    })
                })
            }
        })
    })
    let displayData;
    if (presentation === 'Randomly') {
        displayData = randomizeArray(resultItems)
    } else {
        displayData = resultItems
    }
    const metaFieldData = {
        questionIndex: 0,
        correctAnswerCount: 0,
        questionsAnswered: [],
        data: adjectiveRemixState
    }

    return { displayData, metaFieldData }

};
