import {
    GET_CHILDREN,
    GET_CHILDREN_SUCCESS,
    GET_CHILDREN_ERROR,
    SET_CHILDREN_SELECTED,
    GET_CHILDREN_GRADES,
    SET_CHILDREN_GRADES
} from '../constants'

export const getChildren = () => {
    return {
        type: GET_CHILDREN,
    }
}

export const getChildrenGrades = () => {
    return {
        type: GET_CHILDREN_GRADES,
    }
}

export const getChildrenSuccess = (payload) => ({
    type: GET_CHILDREN_SUCCESS,
    payload,
})

export const getChildrenError = (payload) => ({
    type: GET_CHILDREN_ERROR,
    payload,
})

export const setChildrenSelected = (payload) => ({
    type: SET_CHILDREN_SELECTED,
    payload,
})

export const setChildrenGrades = (payload) => ({
    type: SET_CHILDREN_GRADES,
    payload,
})
