import { useEffect, useRef} from 'react';

export default function useIntervalHook(callback, delay) {
 const savedCallback = useRef();

 // Remember the latest function.
 useEffect(() => {
  savedCallback.current = callback;
 }, [callback]);

 // Set up the interval.
 useEffect(() => {
  function tick() {
   savedCallback.current();
  }
  if (delay !== null) {
   let id = setInterval(tick, delay);
   return () => clearInterval(id);
  }
 }, [delay]);
}