import { put, select } from 'redux-saga/effects'
import { selectGameAPIData, selectGameAPIFrom } from '../selectors'
import * as actions from '../actions'
import { randomizeArray } from '../../../utils/util';

export function* getSentenceCompletionData(payload, getGameData) {
    const { from, language, screenItems } = payload;
    let gameData = yield select(selectGameAPIData)
    let fromGame = yield select(selectGameAPIFrom)
    if (!gameData || !fromGame || fromGame !== 'SENTENCE_COMPLETION') {
        gameData = yield* getGameData(
            null,
            null,
            null,
            'SENTENCE_COMPLETION',
            from,
            null,
            null,
        )
        yield put(actions.setGameData({ apiData: gameData, fromGame: 'SENTENCE_COMPLETION' }))
    }

    let resultItems = []

    gameData.map((item, id) => {
        let [sentence, space] = item[`sentence_${language}`].split('_');
        resultItems.push({
            audio: item.audio,
            audio_path: item[`audio_path_${language}`],
            id: item.id,
            sentence: sentence,
            answers: randomizeArray(
                [
                    { id: 1, answerText: item[`correct1_${language}`], isVisible: true, isCorrectAnswer: 1 },
                    { id: 2, answerText: item[`correct2_${language}`], isVisible: true, isCorrectAnswer: 1 },
                    ...[...Array.from({ length: screenItems }, (v, index) =>
                        ({ id: index + 3, answerText: item[`wrong${index + 1}_${language}`], isVisible: true, isCorrectAnswer: 0 }),
                    )],
                ]
            )
        })
    })

    const displayData = randomizeArray(resultItems);
    const metaFieldData = {
        activeItemIndex: 0,
        gameType: 1,
        activeAnswers: displayData[0].answers,
        openLeftSidebar: false,
        webCamDataFields: { webCamFields: {} }
    }

    return { displayData, metaFieldData }

};
