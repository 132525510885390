import { put, select } from 'redux-saga/effects'
import { selectGameAPIData } from '../selectors'
import * as actions from '../actions'
import { randomizeArray } from '../../../utils/util';


export function* getGrammarSkiData(payload, getGameData) {
    const { from, selectedSyllabes, heScreens, screens } = payload;

    let skiState = yield select(selectGameAPIData)

    if (!skiState) {
        const skiData = yield* getGameData(
            null,
            null,
            null,
            'GRAMMARSKI',
            from,
            null,
            null
        )
        const farmData = yield* getGameData(
            null,
            null,
            null,
            'FARM',
            from,
            null,
            null
        )
        const beachData = yield* getGameData(
            null,
            null,
            null,
            'BEACH',
            from,
            null,
            null
        )
        skiState = { skiData: skiData.questions, farmData: farmData.questions, beachData: beachData.questions };
        yield put(actions.setGameData({ apiData: skiState }))
    }

    let resultItems = [];

    skiState.skiData.forEach((item, idx) => {
        let answerData = [];
        let correctAnswer = { id: item.id + '1', isVisible: true, word: item.correct }

        answerData.push(correctAnswer)
        for (let i = 1; i < heScreens; i++) {
            answerData.push(
                { id: item.id + i, isVisible: true, word: item[`level${i}`] },
            )

        }
        resultItems.push({
            id: item.id,
            imagePath: item.image_path,
            image: item.image,
            answers: randomizeArray(answerData),
            sentence: item.question,
            correct: item.correct,
            sound: item.sound,
            sound_path: item.sound_path,
            category: 'He/She',
            flag: 'he'
        })

    })
    skiState.farmData.forEach((item, idx) => {
        let answerData = [];
        let correctAnswer = { id: item.id + '1', isVisible: true, word: item.correct }

        answerData.push(correctAnswer)
        for (let i = 1; i < screens; i++) {
            answerData.push(
                { id: item.id + i, isVisible: true, word: item[`level${i}`] },
            )

        }
        resultItems.push({
            id: item.id,
            imagePath: item.image_path,
            image: item.image,
            answers: randomizeArray(answerData),
            sentence: item.question,
            correct: item.correct,
            sound: item.sound,
            sound_path: item.sound_path,
            category: 'Irregular Past Tense',
            flag: 'past'
        })

    })
    skiState.beachData.forEach((item, idx) => {
        let answerData = [];
        let correctAnswer = { id: item.id + '1', isVisible: true, word: item.correct }

        answerData.push(correctAnswer)

        answerData.push(
            { id: item.id, isVisible: true, word: item.foil },
        )
        resultItems.push({
            id: item.id,
            imagePath: item.image_path,
            image: item.image,
            answers: randomizeArray(answerData),
            sentence: item.question,
            correct: item.correct,
            sound: item.sound,
            sound_path: item.sound_path,
            category: '3rd Person Singular',
            flag: 'singular'
        })

    })
    if (selectedSyllabes.length === 1 && selectedSyllabes.includes(1)) {
        resultItems = resultItems.filter((item) => item.flag === 'past')
    } else if (selectedSyllabes.length === 1 && selectedSyllabes.includes(2)) {
        resultItems = resultItems.filter((item) => item.flag === 'he')
    } else if (selectedSyllabes.length === 1 && selectedSyllabes.includes(3)) {
        resultItems = resultItems.filter((item) => item.flag === 'singular')
    } else if (selectedSyllabes.length === 2 && (selectedSyllabes.includes(1) && selectedSyllabes.includes(2))) {
        resultItems = resultItems.filter((item) => item.flag === 'past' || item.flag === 'he')
    } else if (selectedSyllabes.length === 2 && (selectedSyllabes.includes(2) && selectedSyllabes.includes(3))) {
        resultItems = resultItems.filter((item) => item.flag === 'he' || item.flag === 'singular')
    } else if (selectedSyllabes.length === 2 && (selectedSyllabes.includes(3) && selectedSyllabes.includes(1))) {
        resultItems = resultItems.filter((item) => item.flag === 'singular' || item.flag === 'past')
    }
    const displayData = randomizeArray(resultItems)

    const metaFieldData = {
        activeItemIndex: 0,
        gameData: displayData,
        droppedAnswer: [],
        invisibleAnswerIds: [],
        questionsAnswered: [],
        activeAnswers: displayData[0].answers,
        correctAnswerCount: 0,
        activeType: 'receptive'
    }
    return { displayData, metaFieldData }
}