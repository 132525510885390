import { initialState } from './reducers'

export const select = (state) => state || initialState

export const selectChild = (state) => select(state).gamesSelection.childSelected
export const selectSelections = (state) =>
    select(state).gamesSelection.selectionsSelected

export const selectChildrenWithSelection = (state) =>
    select(state).gamesSelection.childrenWithSelection

export const selectGameAPIData = (state) => select(state).gamesSelection.currentGameData.apiData
export const selectBakeryAPIData = (state) => select(state).gamesSelection.bakeryGameData.apiData
export const selectGameAPIFrom = (state) => select(state).gamesSelection.currentGameData.fromGame
export const selectGameCategoryData = (state) => select(state).gamesSelection.currentGameData.categoryData

export const selectIsLoading = (state) => select(state).gamesSelection.loading

export const childrenSelectedForPlay = (state) => select(state).children.childrenSelected
export const allGames = (state) => select(state).gamesSelection.allGames
export const gameCategories = (state) => select(state).gamesSelection.gameCategories
export const gameLanguages = (state) => select(state).gamesSelection.gameLanguages