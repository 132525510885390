import React, { Fragment, useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux'
import './note.scss'
import { CREATE_NOTE_USER } from '../../../utils/endpoint'
import GET_API_KEY from '../../../utils/getApiKey'
import AvatarChild from '../../common/AvatarChild/AvatarChild'
import useOnClickOutside from "../../../hooks/use-onclick-outside";
import { encodeHtmlTags } from '../../../utils/util'


const NotesDropdown = ({ open, handleClose, activity }) => {
    const { childrenSelected } = useSelector(state => state.children)
    const [activeTab, setActiveTab] = useState('')
    const [myNotes, setMyNotes] = useState([])
    const [showNotes, setShowNotes] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [activeNotes, setActiveNotes] = useState('')

    const notify = () => toast.success('Notes saved', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
    });
    // Create a ref that we add to the element for which we want to detect outside clicks
    const ref = useRef();
    useEffect(() => { setShowNotes(open) }, [open])
    // Call hook passing in the ref and a function to call on outside click
    useOnClickOutside(ref, () => handleClose());

    useEffect(() => {
        for (let i in childrenSelected) {
            myNotes.push(childrenSelected[i])
            myNotes[i].note = ""
        }
    }, [childrenSelected])

    const activeTabHandler = (stuId) => {
        for (let i in myNotes) {
            if (myNotes[i].studentId == stuId) {
                setActiveNotes(myNotes[i].note)
                document.getElementById("note-" + stuId).focus()
            }
        }
        setActiveTab(`tab-${stuId}`)
    }

    const handleChangeNote = (e, id) => {
        for (let i in myNotes) {
            if (myNotes[i].studentId == id) {
                myNotes[i].note = e.target.value
            }
        }
        setMyNotes(myNotes);
    };

    //Handler for managing textarea data
    const onClickHandler = (studentId) => {
        const data = [];
        for (let i in myNotes) {
            if (myNotes[i].note != "" && myNotes[i].studentId != undefined) {
                const bodyParameters = {
                    student_id: myNotes[i].studentId,
                    type: activity,
                    activity: activity,
                    note: encodeHtmlTags(myNotes[i].note)
                };
                data.push(bodyParameters);
            }
        }

        if (data.length > 0) {
            const API_KEY = GET_API_KEY()
            const params = {
                headers: {
                    Authorization: 'Bearer ' + API_KEY,
                }
            }
            activeTabHandler(studentId)
            setIsLoading(true)

            const stringifyData = JSON.stringify(data);
            axios.post(CREATE_NOTE_USER, { data: stringifyData }, params)
                .then((response) => {

                    setIsLoading(false)
                    notify();
                    setTimeout(() => { handleClose() }, 3000)

                }, (error) => {
                    setIsLoading(false)
                    console.log(error)
                    if (error.message.indexOf("401") >= 0) {
                        localStorage.clear()
                        window.location.replace(process.env.REACT_APP_PAGE_BACKEND + 'login')
                    }
                });
        }
    }
    const Students = ({ index, student }) => {
        return (
            <Fragment>
                <li key={index} className="nav-item notes_box" role="presentation">
                    <a
                        className={`nav-link ${activeTab === `tab-${student.studentId}` ? 'active' : ''
                            }`}
                        id="home-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                        onClick={() => activeTabHandler(student.studentId)}
                        key={`${index}-${student.studentId}`}
                    >
                        <AvatarChild avatar={student?.avatar} />
                        <span>{student.studentName}</span>
                    </a>
                </li>
            </Fragment>
        )
    }

    //Replace divs with this when API is working
    const TextArea = ({ index, student }) => {
        return (
            <div
                className={`tab-pane fade ${activeTab === `tab-${student.studentId}` ? 'active show dropbox' : 'dropbox'
                    }`}
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
            >
                <div className="dr-para dropbox">
                    <textarea
                        id={"note-" + student.studentId}
                        key={index}
                        defaultValue={activeNotes}
                        className="notes dropbox" rows="10"
                        placeholder="write text"
                        name={`${student.studentId}_value`}
                        onChange={(e) => handleChangeNote(e, student.studentId)}
                    ></textarea>
                </div>
                <button id={student.studentId} onClick={() => onClickHandler(student.studentId)} role="button" className="dr-sve-btn dropbox">
                    {isLoading ? <div className="spinner-border spinner-border-sm text-white" role="status">
                        <span className="sr-only">Loading...</span>
                    </div> : "Save"}
                </button>
            </div>
        )
    }

    return (
        <div id="note_dropdown" ref={ref} className={`note-dropdown ${showNotes ? 'active dropbox' : 'dropbox'}`}>
            <span className="fr dropbox"></span>
            <p className="note-dr-p dropbox">Notes</p>
            <ul className="nav nav-tabs dropbox" id="myTab" role="tablist">
                {
                    childrenSelected && childrenSelected.length > 0 && childrenSelected.map((item, index) => {
                        return <Students key={'student' + index} index={index} student={item} />
                    })
                }
            </ul>
            <div className="tab-content" id="myTabContent">
                {
                    childrenSelected && childrenSelected.length > 0 && childrenSelected.map((item, index) => {
                        return <TextArea key={'contents' + index} index={index} student={item} />
                    })
                }
            </div>
        </div>
    )
}

export default NotesDropdown;
