import {
    SET_PREPOSITION_DATA,
} from '../constants'

export const initialState = {
    prepositionData: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_PREPOSITION_DATA: {
            return {
                prepositionState: action.payload
            }
        }
        default:
            return state
    }
}
