import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as constants from '../constants'
import * as actions from './actions'
import network from '../../utils/network'
import { getEndpointURL } from '../../utils/endpoint'
import GET_API_KEY from '../../utils/getApiKey'

function* getAdjectiveRemixData({payload}) {
    const API_KEY = GET_API_KEY()
    try {
        //Set data empty on language change
        yield put(actions.setAdjectiveRemixStateAction(null))
        const action = getEndpointURL('ADJECTIVE_REMIX')
        const params = {
            headers: {
                Authorization: 'Bearer ' + API_KEY,
            }
        }
        const languageSelected = payload;
        const { body } = yield call(network.getData, `${action}/${languageSelected}`, params);                
        yield put(actions.setAdjectiveRemixStateAction(body))
    } catch (error) {
        if (error.message.indexOf("401") >= 0) {
            localStorage.clear()
            window.location.replace(process.env.REACT_APP_PAGE_BACKEND + 'login')
        }
     }

}

export default function* () {
    yield all([
        takeEvery(constants.GET_ADJECTIVE_REMIX_DATA, getAdjectiveRemixData),
    ])
}

