import { put, select } from 'redux-saga/effects'
import { selectGameAPIData } from '../selectors'
import * as actions from '../actions'
import { randomizeArray } from '../../../utils/util';


export function* getXrayPhonoData(payload, getGameData) {
    const { from, selectedSyllabes, screens } = payload;

    let xrayState = yield select(selectGameAPIData)

    if (!xrayState) {
        xrayState = yield* getGameData(
            null,
            null,
            null,
            'XRAY_PHONO',
            from,
            null,
            null
        )
        yield put(actions.setGameData({ apiData: xrayState }))
    }


    let resultItems = [];

    xrayState.filter(item => selectedSyllabes.includes(parseInt(item.correct_syllable))).map((item, idx) => {
        let answerData = [];
        let correctAnswer = { id: item.id + '1', isVisible: true, answerImage: item.correct_image, answerAudio: item.correct_sound, answerSyllable: parseInt(item.correct_syllable) }

        answerData.push(correctAnswer)
        for (let i = 1; i < screens; i++) {
            answerData.push(
                { id: item.id + i, isVisible: true, answerImage: item[`wrong${i}_image`], answerAudio: item[`wrong${i}_sound`], answerSyllable: parseInt(item[`wrong${i}_syllable`]) },
            )
        }

        return (
            resultItems.push({
                id: item.id,
                audioPath: item.audio_path,
                imagePath: item.image_path,
                currentItemSyllable: parseInt(item.correct_syllable),
                answers: randomizeArray(answerData)
            })
        )
    })



    const displayData = randomizeArray(resultItems)
    const metaFieldData = {
        activeItemIndex: 0,
        gameData: displayData,
        droppedAnswer: [],
        invisibleAnswerIds: [],
        questionsAnswered: [],
        activeAnswers: displayData[0].answers,
        correctAnswerCount: 0,
    }
    return { displayData, metaFieldData }
}