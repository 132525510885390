import {
    GET_PREPOSITION_DATA, SET_PREPOSITION_DATA
} from '../constants';

const getPrepositionStateAction = () => {
    return {
        type: GET_PREPOSITION_DATA,     
    }
}

const setPrepositionStateAction = (payload) => {
    return {
        type: SET_PREPOSITION_DATA,
        payload
    }
}

export {
    getPrepositionStateAction,
    setPrepositionStateAction
}