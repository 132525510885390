import {
    SET_SOUND_WINDOW_DATA,
} from '../constants'
export const initialState = {
    soundWindowData: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_SOUND_WINDOW_DATA: {
            return {
                soundWindowData: action.payload,
            }
        }
        default:
            return state
    }
}
