import { put, select } from "redux-saga/effects";
import { selectGameAPIData } from "../selectors";
import * as actions from '../actions'
import { randomizeArray } from "../../../utils/util";

export function* getSmartOralMotor(payload, getSmartOralMotorData) {
    const { from, noOfRepetitions, correctAnswerCount } = payload;
    let newSmartOralMotorState = yield select(selectGameAPIData);
    let resultItems = [];
    if (!newSmartOralMotorState) {
        newSmartOralMotorState = yield* getSmartOralMotorData(
            null,
            null,
            null,
            "SMARTORALMOTOR",
            from,
            null,
            null
        )

        yield put(actions.setGameData({ apiData: newSmartOralMotorState }))
    }


    newSmartOralMotorState.questions_en.map((item, idx) => {
        let answerData = [];
        let correctAnswer = {
            id: item.answers[0].id,
            is_correct: item.answers[0].is_correct,
        };

        answerData.push(correctAnswer);
        resultItems.push({
            id: item.id,
            answers: randomizeArray(answerData),
            text: item.text,
            image: item.image,
            video: item.video,
            imagePath: newSmartOralMotorState.image_files_path,
            videoPath: newSmartOralMotorState.description_video_path,
            video_files_path: newSmartOralMotorState.video_files_path,
            category_type: item.category_type,


        })
    });
    const displayData = randomizeArray(resultItems);
    const metaFieldData = {
        activeItemIndex: 0,
        finalSliderData: displayData,
        correctAnswerCount: 0,
        noOfRepetitions: noOfRepetitions,
        questionsAnswered: [],
        correctAnswerCount: correctAnswerCount
    }

    return { displayData, metaFieldData }
}