import { GET_USERS, GET_USERS_ERROR, GET_USERS_SUCCESS } from '../constants'

export const getUsers = () => {
    return {
        type: GET_USERS,
    }
}

export const getUsersSuccess = (payload) => ({
    type: GET_USERS_SUCCESS,
    payload,
})

export const getUsersError = (payload) => ({
    type: GET_USERS_ERROR,
    payload,
})
