import {
    SET_SOCIAL_QUEST_DATA
} from '../constants'

export const initialState = {
    socialQuestData: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_SOCIAL_QUEST_DATA: {            
            return {
                socialQuestState: action.payload
            }
        }
        default:
            return state
    }
}