import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import * as constants from '../constants'
import * as actions from './actions'
import * as wordsFarmActions from '../wordsFarm/actions'
import { selectSocialQuestState } from '../socialQuest/selectors'
import { selectAdjectiveRemixState } from '../adjectiveRemix/selectors'
import network from '../../utils/network'
import { getEndpointURL } from '../../utils/endpoint'
import {
    selectChildrenWithSelection,
    selectGameAPIData,
    selectGameAPIFrom,
    childrenSelectedForPlay,
} from './selectors'
import { serializeQuery, randomizeArray } from '../../utils/util'
import GET_API_KEY from '../../utils/getApiKey'
import { getIsThatSillyData, getPictureNamingData } from './businessLogic'
import { getSentenceCompletionData } from './businessLogic/sentenceCompletion'
import { getWhQuestionsData } from './businessLogic/whQuestions'
import { getVerbQuestData } from './businessLogic/verbQuest'
import { getPrepositionData } from './businessLogic/preposition'
import { getAdjectiveRemixData } from './businessLogic/adjectiveRemix'
import { getDitmData } from './businessLogic/ditm'
import { getInferencingData } from './businessLogic/inferencing'
import { getWhichData } from './businessLogic/which'
import { getSequencingData } from './businessLogic/sequencing'
import { getXrayPhonoData } from './businessLogic/xrayPhono'
import { getRhymingSelectionData } from './businessLogic/rhymingSelection'
import { getPronounHeroesData } from './businessLogic/pronounHeroes'
import { getRhymingMatchData } from './businessLogic/rhymingMatch'
import { getBakeryData } from './businessLogic/bakery'
import { getGroceryData } from './businessLogic/grocery'
import { getGrammarSkiData } from './businessLogic/grammarSki'
import { getZooData } from './businessLogic/Zoo'
import { getRhymingIdentificationData } from './businessLogic/rhymingIdentification'
import { getDecodingData } from './businessLogic/decoding'
import { getLetterNameData } from './businessLogic/letterNames'
import { getWouldRatherData } from './businessLogic/wouldRather'
import { getPictureMatching } from './businessLogic/pictureMatching'
import { getFindImage } from './businessLogic/findImage'
import { getLearningAboutYou } from './businessLogic/learningAboutYou'
import { getBeginningSounds } from './businessLogic/beginningSounds'
import { getWhereDoesItGo } from './businessLogic/whereDoesItGo'
import { getSmartOralMotor } from './businessLogic/smartOralMotor'
import { getSorting } from './businessLogic/sorting'
import { getCategoryNaming } from './businessLogic/CategoryNaming'
import { getMinimalPairsAcademy } from './businessLogic/minimalPairsAcademy'

function* getData(
    phonemeSelected,
    syllableNo,
    level,
    endPoint,
    from,
    section,
    selectedLanguage
) {
    try {
        const action = getEndpointURL(endPoint)
        const API_KEY = GET_API_KEY()

        const params = {
            headers: {
                Authorization: 'Bearer ' + API_KEY,
            },
        }
        let bodyPost = {}
        if (level) {
            bodyPost.level = level
        }
        if (syllableNo) {
            bodyPost.syllableNumbers = syllableNo
        }
        if (from === 'syllableSplash') {
            bodyPost = { limit: 100 }
        } else if (from === 'findThePicture' || from === 'useThePronoun') {
            bodyPost = { target: level }
        } else if (from === 'funFunctional' || from === 'goTogether') {
            if (section && section !== null) {
                bodyPost = { section: section }
            } else {
                bodyPost = {}
            }
        } else if (from === 'yesNoBarn') {
            if (section && section !== null) {
                bodyPost = { category_id: section }
            } else {
                bodyPost = {}
            }
        } else if (
            [
                'pictureIdentification',
                'TextImageMatch',
                'ImageTextMatch',
                'ReadAndAnswer',
                'phrase-builder',
            ].includes(from)
        ) {
            if (section && section !== null) {
                bodyPost = { category: section }
            } else {
                bodyPost = {}
            }
        } else if (phonemeSelected) {
            if (phonemeSelected.symbol) {
                bodyPost.symbol = phonemeSelected.symbol.replace(/\//g, '')
            } else {
                bodyPost.symbol = phonemeSelected.phoneme
            }
        }
        const queryData = yield call(serializeQuery, bodyPost)
        const actionUrl = selectedLanguage
            ? `${action}/${selectedLanguage}`
            : action
        const { body } = yield call(
            network.getData,
            actionUrl,
            params,
            queryData
        )
        if (from === 'TextImageMatch') {
            const finalData = {
                phrases: randomizeArray(body[0].phrases),
                words: randomizeArray(body[0].words),
            }
            return [finalData]
        } else {
            return body
        }
    } catch (error) {
        if (error.message.indexOf('401') >= 0) {
            localStorage.clear()
            window.location.replace(
                process.env.REACT_APP_PAGE_BACKEND + 'login'
            )
        }
    }
}

function* handleNewSelection({ payload }) {
    console.log('handleNewSelection', payload)
    try {
        yield put(actions.setSelectionLoading({ loading: true }))
        const {
            from,
            level,
            phonemeSelected,
            syllableNo,
            endPoint,
            sessionId,
            section,
            groupingPronounSelected,
            apiData,
        } = payload
        let { metaFields, childSelected } = payload
        let resultToShow
        let levelsAvailability = {}
        let selectionData = {}
        let settings = {}
        if (from === 'stories') {
            resultToShow = []
        } else if (from === 'reading-comprehensions') {
            const { selectedStoryId, selectedStory, selectLevel } = payload
            selectionData.selectedStoryId = selectedStoryId
            selectionData.isQuizActive = false
            selectionData.selectLevel = selectLevel
            const questions = selectedStory.questions.map((item) => {
                item.randomOptions = randomizeArray([
                    item.foil1,
                    item.foil2,
                    item.foil3,
                    item.correct,
                ])
                return item
            })
            selectedStory.questions = questions
            resultToShow = [selectedStory]
        } else if (from === 'words-farm' || from === 'apraxia-farm') {
            yield put(wordsFarmActions.getWordsFarmStateAction())
            resultToShow = []
        } else if (from === 'social-quest') {
            const { selectedLocations, isExpressive } = payload
            selectionData.selectedLocations = selectedLocations
            selectionData.isExpressive = isExpressive
            const socialQuestState = yield select(selectSocialQuestState)
            const selecteditems = selectedLocations.map((s) =>
                s.replace('/', '').replace(' ', '')
            )
            const selectedNewLocations = socialQuestState.filter((item) =>
                selecteditems.includes(item.category)
            )
            resultToShow = randomizeArray(
                selectedNewLocations.map((question) => {
                    return {
                        ...question,
                        answers: randomizeArray([
                            {
                                id: question.id + '1',
                                isVisible: true,
                                isAnswered: false,
                                answerText: question.correct1,
                                answerAudio: question.correct1_file,
                            },
                            {
                                id: question.id + '2',
                                isVisible: true,
                                isAnswered: false,
                                answerText: question.correct2,
                                answerAudio: question.correct2_file,
                            },
                            {
                                id: question.id + '3',
                                isVisible: true,
                                isAnswered: false,
                                answerText: question.wrong,
                                answerAudio: question.wrong_file,
                            },
                        ]),
                    }
                })
            )
            if (resultToShow && resultToShow.length) {
                metaFields = { activeAnswers: resultToShow[0].answers }
            }
        } else if (from === 'adjectiveRemix') {
            console.log(payload)
            const {
                unSelectedItems,
                unSelectedCategories,
                displayText,
                moveAutomatically,
                presentation,
                language,
            } = payload
            selectionData.unSelectedItems = unSelectedItems
            selectionData.unSelectedCategories = unSelectedCategories
            selectionData.displayText = displayText
            selectionData.moveAutomatically = moveAutomatically
            selectionData.presentation = presentation
            selectionData.language = language

            const { displayData, metaFieldData } = yield getAdjectiveRemixData(
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'where-does-it-go') {
            console.log(payload)
            const { levelDifficulty } = payload
            selectionData.levelDifficulty = levelDifficulty

            const { displayData, metaFieldData } = yield getWhereDoesItGo(
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'sound-window') {
            resultToShow = []
        } else if (from === 'ditm') {
            const { displayData, metaFieldData } = yield getDitmData(
                payload,
                getData
            )
            resultToShow = displayData
            metaFields = metaFieldData
        } else if (from === 'preposition') {
            const { unSelectedLevels, unSelectedItems } = payload
            selectionData.unSelectedItems = unSelectedItems
            selectionData.unSelectedLevels = unSelectedLevels
            const { displayData, metaFieldData } = yield getPrepositionData(
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'verbQuest') {
            console.log('KAPA', payload)
            const {
                unSelectedItems,
                autoProgression,
                readCorrectAnswer,
                expressiveCorrectAnswerCount,
                gameType,
                receptiveCorrectAnswerCount,
                questionsAnswered,
                showCorrectAnswer,
            } = payload
            selectionData.autoProgression = autoProgression
            selectionData.readCorrectAnswer = readCorrectAnswer
            selectionData.unSelectedItems = unSelectedItems
            const { displayData, metaFieldData } = yield getVerbQuestData(
                payload,
                getData
            )
            resultToShow = displayData
                ? randomizeArray(JSON.parse(JSON.stringify(displayData)))
                : displayData
            metaFields = metaFieldData
            metaFields.expressiveCorrectAnswerCount =
                expressiveCorrectAnswerCount || 0
            metaFields.gameType = gameType || 1
            metaFields.receptiveCorrectAnswerCount =
                receptiveCorrectAnswerCount || 0
            metaFieldData.questionsAnswered = questionsAnswered || []
            metaFieldData.showCorrectAnswer = showCorrectAnswer || false
        } else if (from === 'isThatsilly') {
            const { languagePrompt, displayClue } = payload
            selectionData.languagePrompt = languagePrompt
            selectionData.displayClue = displayClue
            const { displayData, metaFieldData } = yield getIsThatSillyData(
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'inferencing') {
            const { displayData, metaFieldData } = yield getInferencingData(
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'which') {
            const { language } = payload
            selectionData.language = language
            const { displayData, metaFieldData } = yield getWhichData(
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'phrase-builder') {
            let phraseState = yield select(selectGameAPIData)
            let fromGame = yield select(selectGameAPIFrom)
            const { language, section } = payload
            selectionData.language = language
            if (
                !phraseState ||
                !!section ||
                !fromGame ||
                fromGame !== 'PHRASE_BUILDER'
            ) {
                phraseState = yield* getData(
                    null,
                    null,
                    null,
                    'PHRASE_BUILDER',
                    from,
                    section,
                    null
                )
                yield put(
                    actions.setGameData({
                        apiData: phraseState,
                        fromGame: 'PHRASE_BUILDER',
                    })
                )
            }

            let resultItems = []
            phraseState.map((item, id) => {
                resultItems.push({
                    category: item.category,
                    id: item.id,
                    image: item.image,
                    image_path: item.image_path,
                    answers: randomizeArray([
                        {
                            id: item.id + `1`,
                            answerText: item[`option1_${language}`],
                            option: 0,
                            btnColor: '',
                            isVisible: true,
                        },
                        {
                            id: item.id + `2`,
                            answerText: item[`option2_${language}`],
                            option: 1,
                            btnColor: 'purple-button',
                            isVisible: true,
                        },
                        {
                            id: item.id + `3`,
                            answerText: item[`option3_${language}`],
                            option: 2,
                            btnColor: 'light-blue-button',
                            isVisible: true,
                        },
                    ]),
                })
            })

            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(resultItems))
            )
            metaFields = {
                ...payload.metaFields,
                ...{
                    activeItemIndex: 0,
                    droppedAnswer: [],
                    invisibleAnswerIds: [],
                    questionsAnswered: [],
                },
            }
        } else if (from === 'ImageTextMatch') {
            const body = yield* getData(
                phonemeSelected,
                syllableNo,
                level,
                endPoint,
                from,
                section
            )
            let screenItems
            if (payload.metaFields) {
                const newScreenItems = payload.metaFields.settings.screenItems
                screenItems = newScreenItems + 1
            } else {
                screenItems = 5
            }

            let randomizedAnswersData = {}
            const apiData = body.data || body
            for (const type in apiData[0]) {
                if (!randomizedAnswersData[type]) {
                    randomizedAnswersData[type] = []
                }
                for (const item of apiData[0][type]) {
                    randomizedAnswersData[type].push({
                        ...item,
                        colors: randomizeArray([
                            '#662d91',
                            '#00a651',
                            '#00aeef',
                            '#2e3192',
                            '#f26d7d',
                        ]),
                        answersEN: randomizeArray([
                            item.correct_en,
                            ...[
                                ...Array.from(
                                    { length: screenItems - 1 },
                                    (v, index) => item[`wrong${index + 1}_en`]
                                ),
                            ],
                        ]),
                        answersES: randomizeArray([
                            item.correct_es,
                            ...[
                                ...Array.from(
                                    { length: screenItems - 1 },
                                    (v, index) => item[`wrong${index + 1}_es`]
                                ),
                            ],
                        ]),
                        answersPT: randomizeArray([
                            item.correct_pt,
                            ...[
                                ...Array.from(
                                    { length: screenItems - 1 },
                                    (v, index) => item[`wrong${index + 1}_pt`]
                                ),
                            ],
                        ]),
                        answersNL: randomizeArray([
                            item.correct_nl,
                            ...[
                                ...Array.from(
                                    { length: screenItems - 1 },
                                    (v, index) => item[`wrong${index + 1}_nl`]
                                ),
                            ],
                        ]),
                    })
                }
            }
            resultToShow = {
                words: randomizeArray(randomizedAnswersData.words),
                phrases: randomizeArray(randomizedAnswersData.phrases),
            }
            levelsAvailability = body.levels || {}
        } else if (from === 'WhQuestions') {
            const { questionTypesSelected, language } = payload
            selectionData.questionTypesSelected = questionTypesSelected
            selectionData.language = language
            const { displayData, metaFieldData } = yield getWhQuestionsData(
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'sentenceCompletion') {
            const { language, screenItems } = payload
            selectionData.language = language
            selectionData.screenItems = screenItems
            const { displayData, metaFieldData } =
                yield getSentenceCompletionData(payload, getData)
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'sequencing') {
            const { steps } = payload
            selectionData.steps = steps
            const { displayData, metaFieldData } = yield getSequencingData(
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'xray-phono') {
            const { screens, selectedSyllabes } = payload
            selectionData.screens = screens
            selectionData.selectedSyllabes = selectedSyllabes
            const { displayData, metaFieldData } = yield getXrayPhonoData(
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'rhyming-selection') {
            const { displayData, metaFieldData } =
                yield getRhymingSelectionData(payload, getData)
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'pronoun-heroes') {
            const { displayData, metaFieldData, phraseItems } =
                yield getPronounHeroesData(payload, getData)
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
            resultToShow = {
                word: randomizeArray(displayData),
                phrase: randomizeArray(phraseItems),
            }
        } else if (from === 'rhyming-match') {
            const { displayData, metaFieldData } = yield getRhymingMatchData(
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'rhyming-identification') {
            const { displayData, metaFieldData } =
                yield getRhymingIdentificationData(payload, getData)
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'bakery') {
            const { screens, selectedSyllabes } = payload
            selectionData.screens = screens
            selectionData.selectedSyllabes = selectedSyllabes
            const { displayData, metaFieldData } = yield getBakeryData(
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'grocery') {
            const { screens, selectedSyllabes } = payload
            selectionData.screens = screens
            selectionData.selectedSyllabes = selectedSyllabes
            const { displayData, metaFieldData } = yield getGroceryData(
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'letter-names') {
            const { screens } = payload
            selectionData.screens = screens
            const { displayData, metaFieldData } = yield getLetterNameData(
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'would-rather') {
            const { displayData, metaFieldData } = yield getWouldRatherData(
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'grammar-ski') {
            const { screens, selectedSyllabes, heScreens } = payload
            selectionData.heScreens = heScreens
            selectionData.screens = screens
            selectionData.selectedSyllabes = selectedSyllabes
            const { displayData, metaFieldData } = yield getGrammarSkiData(
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'zoo') {
            const { screens, selectedSyllabes, heScreens } = payload
            selectionData.heScreens = heScreens
            selectionData.screens = screens
            selectionData.selectedSyllabes = selectedSyllabes
            const { displayData, metaFieldData } = yield getZooData(
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'pronoun-type-guide') {
            metaFields = {
                activeItemIndex: 0,
            }
        } else if (from === 'what-pronoun-guide') {
            const { language } = payload
            selectionData.language = language
            metaFields = {
                activeItemIndex: 0,
            }
        } else if (from === 'decoding-room') {
            const { displayData, metaFieldData } = yield getDecodingData(
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
            const sounds = displayData.filter(
                (dt) => dt.name === 'BlendingSounds'
            )
            const syllable = displayData.filter(
                (dt) => dt.name === 'BlendingSyllables'
            )
            resultToShow = {
                sounds: randomizeArray(sounds),
                syllable: randomizeArray(syllable),
            }
        } else if (from === 'pictureNaming') {
            const { unSelectedCategories, language, automaticAdvance } = payload
            selectionData.unSelectedCategories = unSelectedCategories
            selectionData.automaticAdvance = automaticAdvance
            selectionData.language = language
            const { displayData, metaFieldData } = yield getPictureNamingData(
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'picture-matching') {
            const { unSelectedCategories, screens } = payload
            selectionData.unSelectedCategories = unSelectedCategories
            selectionData.screens = screens
            const { displayData, metaFieldData } = yield getPictureMatching(
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'does-not-belong') {
            const { screens, language } = payload
            selectionData.screens = screens
            selectionData.language = language
            const { displayData, metaFieldData } = yield getFindImage(
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'beginning-sounds') {
            const { screens } = payload
            selectionData.screens = screens
            const { displayData, metaFieldData } = yield getBeginningSounds(
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'learning-about-you') {
            const { screens } = payload
            selectionData.screens = screens
            const { displayData, metaFieldData } = yield getLearningAboutYou(
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'where-does-it-go') {
            console.log('SAGA WDIT')
            const { screens } = payload
            selectionData.screens = screens
            const { displayData, metaFieldData } = yield getWhereDoesItGo(
                // HERE IS THE PROB
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'smart-oral-motor') {
            const { noOfRepetitions } = payload
            selectionData.noOfRepetitions = noOfRepetitions
            const { displayData, metaFieldData } = yield getSmartOralMotor(
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'sorting') {
            const { noOfRepetitions } = payload
            selectionData.noOfRepetitions = noOfRepetitions
            const { displayData, metaFieldData } = yield getSorting(
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'category-naming') {
            const { noOfRepetitions } = payload
            selectionData.noOfRepetitions = noOfRepetitions
            const { displayData, metaFieldData } = yield getCategoryNaming(
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'minimal-pairs-academy') {
            const { noOfRepetitions } = payload
            selectionData.noOfRepetitions = noOfRepetitions
            const { displayData, metaFieldData } = yield getMinimalPairsAcademy(
                payload,
                getData
            )
            resultToShow = randomizeArray(
                JSON.parse(JSON.stringify(displayData))
            )
            metaFields = metaFieldData
        } else if (from === 'flashCard') {
            console.log('FLASHCARD', payload)
            resultToShow = payload.questions
            levelsAvailability = {}
        } else {
            const body = yield* getData(
                phonemeSelected,
                syllableNo,
                level,
                endPoint,
                from,
                section
            )

            resultToShow = randomizeArray(body.data || body)
            levelsAvailability = body.levels || {}
        }
        console.log('PREPARE DATA')
        let childrenWithSelection = yield select(selectChildrenWithSelection)
        let filterSelection = []
        if (childrenWithSelection[sessionId]) {
            filterSelection = childrenWithSelection[sessionId]?.filter(
                (childWithSelection) => {
                    return (
                        childWithSelection.child.studentId !==
                        childSelected.studentId
                    )
                }
            )
        } else {
            let selectedChildren = yield select(childrenSelectedForPlay)
            childSelected = selectedChildren[0]
        }

        if (metaFields) {
            if (from === 'verbQuest') {
                metaFields = {
                    ...metaFields,
                    activityData: metaFields.activityData,
                }
            } else if (from === 'preposition') {
                metaFields = {
                    ...metaFields,
                    activityData: metaFields.activityData,
                }
            }
        }

        let prepareData = {
            [sessionId]: [
                ...filterSelection,
                {
                    child: childSelected,
                    selection: {
                        level,
                        phonemeSelected,
                        groupingPronounSelected,
                        syllableNo,
                        ...selectionData,
                    },
                    resultToShow: resultToShow,
                    activeIndex: 0, // For tracking slides position
                    metaFields: metaFields ? metaFields : {}, // for tracking children activity ex: in guess-what we need to track student flipped card
                    levelsAvailability: levelsAvailability,
                    apiData: apiData,
                },
            ],
        }
        console.log('PREPARE DATA', prepareData)
        yield put(actions.setWithSelectionSuccess(prepareData))

        yield put(
            actions.setSelectionsSelected({
                level,
                phonemeSelected,
                syllableNo,
            })
        )
        yield put(actions.setSelectionLoading({ loading: false }))
    } catch (error) {
        console.log({ error })
        yield put(actions.setWithSelectionError({ error }))
    }
}

function* handleChildrenChange({ payload }) {
    try {
        const {
            previousChild,
            activeIndex = 0,
            metaFields = {},
            sessionId,
            childStateData,
        } = payload
        let childrenWithSelection = yield select(selectChildrenWithSelection)
        const updateIndex = childrenWithSelection[sessionId]?.findIndex(
            (child) => child.child.studentId === previousChild.studentId
        )
        if (updateIndex > -1) {
            childrenWithSelection[sessionId][updateIndex].activeIndex =
                activeIndex
            childrenWithSelection[sessionId][updateIndex].lastSwitchTime =
                new Date()
            childrenWithSelection[sessionId][updateIndex].metaFields = {
                ...childrenWithSelection[sessionId][updateIndex].metaFields,
                ...metaFields,
            }
            childrenWithSelection[sessionId][updateIndex].childLastStateData =
                childStateData
            yield put(actions.setWithSelectionSuccess(childrenWithSelection))
        }
    } catch (error) {
        console.log(error)
        yield put(actions.setWithSelectionError({ error }))
    }
}

function* handleChildrenUpdate({ payload }) {
    try {
        const { questionNo, sessionId, storyId, childSelected, presentation } =
            payload
        let childrenWithSelection = yield select(selectChildrenWithSelection)
        const storyData = { questionNo, storyId, presentation }
        const childIndex = childrenWithSelection[sessionId]?.findIndex(
            (child) => child.child.studentId === childSelected.studentId
        )
        if (childIndex > -1) {
            let selectedChildStoriesData =
                childrenWithSelection[sessionId][childIndex].selection
                    .storiesData
            if (!selectedChildStoriesData) {
                selectedChildStoriesData = [storyData]
            } else {
                const storyDataIndex = selectedChildStoriesData.findIndex(
                    (data) =>
                        data.storyId === storyId &&
                        data.presentation === presentation
                )
                if (storyDataIndex > -1) {
                    selectedChildStoriesData[storyDataIndex] = storyData
                } else {
                    selectedChildStoriesData.push(storyData)
                }
            }

            childrenWithSelection[sessionId][childIndex].selection = {
                ...childrenWithSelection[sessionId][childIndex].selection,
                storiesData: selectedChildStoriesData,
            }
            yield put(actions.setWithSelectionSuccess(childrenWithSelection))
        }
    } catch (error) {
        console.log({ error })
        yield put(actions.setWithSelectionError({ error }))
    }
}

function* sendGameData({ requestData }) {
    const API_KEY = GET_API_KEY()
    try {
        const action = getEndpointURL('POST_GAME_STATUS')
        const params = {
            headers: {
                Authorization: 'Bearer ' + API_KEY,
            },
        }
        yield call(network.postData, action, requestData, params)
    } catch (error) {
        if (error.message.indexOf('401') >= 0) {
            localStorage.clear()
            window.location.replace(
                process.env.REACT_APP_PAGE_BACKEND + 'login'
            )
        }
        console.log({ error })
    }
}
function* getAllGame() {
    try {
        const API_KEY = GET_API_KEY()
        const action = getEndpointURL('ALLGAMES')
        const params = {
            headers: {
                Authorization: 'Bearer ' + API_KEY,
            },
        }
        console.log('network.getData', network.getData)
        const { body } = yield call(network.getData, action, params)

        yield put(
            actions.getAllGameSuccess(
                body.sort((a, b) => a.game_name.localeCompare(b.game_name))
            )
        )
    } catch (error) {
        if (error.message.indexOf('401') >= 0) {
            localStorage.clear()
            window.location.replace(
                process.env.REACT_APP_PAGE_BACKEND + 'login'
            )
        }
        yield put(actions.getAllGameError({ error }))
    }
}

function* getGameCategories() {
    try {
        const API_KEY = GET_API_KEY()
        const action = getEndpointURL('GAMECATEGORIES')
        const params = {
            headers: {
                Authorization: 'Bearer ' + API_KEY,
            },
        }
        const { body } = yield call(network.getData, action, params)
        yield put(actions.getGameCategoriesSuccess(body))
    } catch (error) {
        if (error.message.indexOf('401') >= 0) {
            localStorage.clear()
            window.location.replace(
                process.env.REACT_APP_PAGE_BACKEND + 'login'
            )
        }
        yield put(actions.getGameCategoriesError({ error }))
    }
}

function* getGameLanguages() {
    try {
        const API_KEY = GET_API_KEY()
        const action = getEndpointURL('GAMELANGUAGES')
        const params = {
            headers: {
                Authorization: 'Bearer ' + API_KEY,
            },
        }
        const { body } = yield call(network.getData, action, params)
        yield put(
            actions.getGameLanguagesSuccess(
                body.sort((a, b) => a.language.localeCompare(b.language))
            )
        )
    } catch (error) {
        if (error.message.indexOf('401') >= 0) {
            localStorage.clear()
            window.location.replace(
                process.env.REACT_APP_PAGE_BACKEND + 'login'
            )
        }
        yield put(actions.getGameLanguagesError({ error }))
    }
}
export default function* () {
    yield all([
        takeEvery(constants.SET_CHILDREN_WITH_SELECTION, handleNewSelection),
        takeEvery(constants.ON_CHILDREN_CHANGE, handleChildrenChange),
        takeEvery(constants.SET_CHILD_DATA, handleChildrenUpdate),
        takeEvery(constants.SEND_GAME_DATA, sendGameData),
        takeEvery(constants.GET_ALL_GAME, getAllGame),
        takeEvery(constants.GET_GAME_CATEGORIES, getGameCategories),
        takeEvery(constants.GET_GAME_LANGUAGES, getGameLanguages),
    ])
}
