import { put, select } from 'redux-saga/effects'
import { selectGameAPIData, selectGameAPIFrom } from '../selectors'
import * as actions from '../actions'
import { randomizeArray } from '../../../utils/util';

export function* getWhQuestionsData(payload, getGameData) {
    const { questionTypesSelected, from, language } = payload
    let allQuestions = yield select(selectGameAPIData)
    let fromGame = yield select(selectGameAPIFrom)
    let displayData;

    if (!allQuestions || !fromGame || fromGame !== 'WH_QUESTIONS') {
        allQuestions = yield* getGameData(
            null,
            null,
            null,
            'WH_QUESTIONS',
            from,
            null,
            null,
        )
        yield put(actions.setGameData({ apiData: allQuestions, fromGame: 'WH_QUESTIONS' }))
    }
    let resultItems = [];
    if (questionTypesSelected.length) {
        displayData = allQuestions.filter((question) => {
            return questionTypesSelected.includes(parseInt(question.type))
        })
    } else {
        displayData = allQuestions
    }

    for (let item of displayData) {
        resultItems.push({
            ...item,
            answers: randomizeArray([
                { id: item.id + '1', isVisible: true, answerText: item[`correct_${language}`], answerAudio: item.answer_audio },
                { id: item.id + '2', isVisible: true, answerText: item[`wrong1_${language}`], answerAudio: item.wrong1_audio },
                { id: item.id + '3', isVisible: true, answerText: item[`wrong2_${language}`], answerAudio: item.wrong2_audio }
            ]),
            correct: item[`correct_${language}`],
            question: item[`question_${language}`],
            sound: item[`sound_${language}`]
        })
    }
    displayData = randomizeArray(resultItems)
    const metaFieldData = {
        activeAnswers: displayData[0].answers,
        questionsAnswered: [],
        receptiveCorrectAnswerCount: 0,
        expressiveCorrectAnswerCount: 0,
        activeSlide: 0
    }
    if (questionTypesSelected.length) {
        return { displayData, metaFieldData }
    } else {
        return {}
    }


};
