import {
    GET_SOUND_WINDOW_DATA, SET_SOUND_WINDOW_DATA
} from '../constants';

const getSoundWindowData = () => {
    return {
        type: GET_SOUND_WINDOW_DATA
    }
}

const setSoundWindowData = (payload) => {
    return {
        type: SET_SOUND_WINDOW_DATA,
        payload
    }
}

export {
    getSoundWindowData,
    setSoundWindowData
}