export const serializeQuery = (obj) => {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    console.log(str)
    return str.join("&");
}

export const randomizeArray = (arrayData) => {
    return arrayData.sort(() => Math.random() - 0.5);
}

export const toCapitalCase = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const tagsToReplace = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;'
};

const replaceTag = (tag) => {
    return tagsToReplace[tag] || tag;
}

export const encodeHtmlTags = (str) => {
    return str.replace(/[&<>]/g, replaceTag);
}
