import { put, select } from "redux-saga/effects";
import { selectGameAPIData } from "../selectors";
import * as actions from "../actions";
import { randomizeArray } from "../../../utils/util";

export function* getFindImage(payload, getFindImageData) {
    const { from, screens, language, correctAnswerCount } = payload;
    let findImageState = yield select(selectGameAPIData);

    if (!findImageState) {
        findImageState = yield* getFindImageData(
            null,
            null,
            null,
            "FINDIMAGE",
            from,
            null,
            null
        );

        yield put(actions.setGameData({ apiData: findImageState }));
    }

    const resultItems = [];
    findImageState.questions_en.map((item, idx) => {
        let answerData = [];
        let correctAnswer = { id: item.answers[0].id, image: item.answers[0].image, is_correct: item.answers[0].is_correct, answerAudio: item.answers[0].audio };

        answerData.push(correctAnswer);
        for (let i = 1; i < screens; i++) {
            answerData.push(
                { id: item.answers[i].id + i, image: item.answers[i].image, is_correct: item.answers[i].is_correct, answerAudio: item.answers[i].audio },
            )
            if (i === 0) {
                answerData.pop()
            }
        }
        return resultItems.push({
            language: item.language,
            id: item.id,
            answers: randomizeArray(answerData),
            text: item.text,
            correct: item.answers[0].id,
            questionId: item.id,
            audioPath: findImageState.audio_files_path
        });

    });
    const displayData = randomizeArray(resultItems);
    const metaFieldData = {
        activeItemIndex: 0,
        questionsAnswered: [],
        correctAnswerCount: correctAnswerCount,
        activeAnswers: displayData[0].answers,
    };
    return { displayData, metaFieldData };
}
