import { put, select } from 'redux-saga/effects'
import { selectGameAPIData } from '../selectors'
import * as actions from '../actions'
import { randomizeArray } from '../../../utils/util';


export function* getZooData(payload, getGameData) {
    const { from, selectedSyllabes, screens } = payload;

    let zooState = yield select(selectGameAPIData)

    if (!zooState) {

        const zooData = yield* getGameData(
            null,
            null,
            null,
            'ZOO',
            from,
            null,
            null
        )
        zooState = { zooData: zooData.questions };
        yield put(actions.setGameData({ apiData: zooState }))
    }


    let resultItems = [];

    zooState.zooData.forEach((item) => {
        let answerData = [];
        let correctAnswer = { id: item.id + '1', isVisible: true, word: item.correct }

        answerData.push(correctAnswer)

        answerData.push(
            { id: item.id, isVisible: true, word: item.foil },
        )
        resultItems.push({
            id: item.id,
            imagePath: item.image_path,
            image: item.image,
            answers: randomizeArray(answerData),
            sentence: item.question,
            correct: item.correct,
            sound: item.sound,
            sound_path: item.sound_path,
            flag: '2'
        })

    })


    const displayData = randomizeArray(resultItems)
    const metaFieldData = {
        activeItemIndex: 0,
        gameData: displayData,
        droppedAnswer: [],
        invisibleAnswerIds: [],
        questionsAnswered: [],
        activeAnswers: displayData[0].answers,
        correctAnswerCount: 0,
        activeType: 'receptive'
    }
    return { displayData, metaFieldData }
}