import { put, select } from 'redux-saga/effects'
import { selectGameAPIData } from '../selectors'
import * as actions from '../actions'
import { randomizeArray } from '../../../utils/util';


export function* getRhymingSelectionData(payload, getGameData) {
    const { from } = payload;

    let rhymingState = yield select(selectGameAPIData)

    if (!rhymingState) {
        rhymingState = yield* getGameData(
            null,
            null,
            null,
            'RHYMING_SELECTION',
            from,
            null,
            null
        )
        yield put(actions.setGameData({ apiData: rhymingState }))
    }


    let resultItems = [];

    rhymingState.map((item, idx) => {
        let answerData = [];
        let targetData = { id: item.id + 't', target_image: item.target_image, target_sound: item.target_sound, target: item.target, word: 'target' }

        let correctAnswer = { id: item.id + '1', isVisible: true, answerImage: item.correct_image, answerAudio: item.correct_sound, word: item.correct, answerSyllable: item.correct }

        answerData.push(correctAnswer)
        for (let i = 1; i < 4; i++) {
            answerData.push(
                { id: item.id + i, isVisible: true, answerImage: item[`wrong${i}_image`], answerAudio: item[`wrong${i}_sound`], word: item[`wrong${i}`], answerSyllable: item[`wrong${i}`] },
            )
        }

        return (
            resultItems.push({
                id: item.id,
                audioPath: item.audio_path,
                imagePath: item.image_path,
                targetData: targetData,
                currentItemSyllable: item.correct,
                answers: randomizeArray(answerData)
            })
        )
    })



    const displayData = randomizeArray(resultItems)
    const metaFieldData = {
        activeItemIndex: 0,
        gameData: displayData,
        droppedAnswer: [],
        invisibleAnswerIds: [],
        questionsAnswered: [],
        activeAnswers: displayData[0].answers,
        correctAnswerCount: 0,
    }
    return { displayData, metaFieldData }
}