import { put, select } from 'redux-saga/effects'
import { selectGameAPIData, selectGameAPIFrom } from '../selectors'
import * as actions from '../actions'
import { randomizeArray } from '../../../utils/util';

export function* getVerbQuestData(payload, getGameData) {
    try {

        let verbQuestState = yield select(selectGameAPIData)
        let fromGame = yield select(selectGameAPIFrom)
        const {
            unSelectedItems,
            activityData,
            from
        } = payload
        let resultItems = []
        if (!verbQuestState || (!fromGame || fromGame !== 'VERB_QUEST')) {
            verbQuestState = yield* getGameData(
                null,
                null,
                null,
                'VERB_QUEST',
                from,
                null,
                null
            )

            yield put(actions.setGameData({ apiData: verbQuestState, fromGame: 'VERB_QUEST' }))
        }
        verbQuestState.map((category) => {
            if (!unSelectedItems.includes(category.title)) {
                category.items.map((item) => {
                    let [prevText, nextText,thirdText, fourthText] = item.question.split('_')
                    resultItems.push({
                        title: category.title,
                        questionPart1: prevText,
                        questionPart2: nextText,
                        questionPart3:thirdText ? thirdText : '',
                        questionPart4: fourthText ? fourthText : "",
                        image: item.image,
                        imagePath: item.image_path,
                        audio: item.audio,
                        audioPath: item.audio_path,
                        correct1: item.correct1,
                        correct2:item.correct2? item.correct2 :'',
                        correct3:item.correct3? item.correct3 :'',
                        wrong1: item.wrong1,
                        wrong2:item.wrong2 ? item.wrong2 : '',
                        wrong3:item.wrong3 ? item.wrong3 : '',
                        id: item.id,
                        answers: randomizeArray(item.correct2 && thirdText ? [
                            {
                                id: item.id + `1`,
                                isVisible: true,
                                answerText: item.correct1,
                            },
                            {
                                id: item.id + `2`,
                                isVisible: true,
                                answerText: item.correct2,
                            },
                            {
                                id: item.id + `3`,
                                isVisible: true,
                                answerText: item.correct3,
                            },
                            {
                                id: item.id + `3`,
                                isVisible: true,
                                answerText: item.wrong1,
                            },
                            
                        ]: [
                            {
                                id: item.id + `1`,
                                isVisible: true,
                                answerText: item.correct1,
                            },
                            
                            {
                                id: item.id + `2`,
                                isVisible: true,
                                answerText: item.wrong1,
                            }
                        ]),
                    })
                })
            }
        })
        const displayData = resultItems
        const metaFieldData = {
            activeItemIndex: 0,
            gameType: 1,
            receptiveCorrectAnswerCount: 0,
            expressiveCorrectAnswerCount: 0,
            activeAnswers: displayData[0].answers,
            showCorrectAnswer1: false,
            showCorrectAnswer2:false,
            questionsAnswered: [],
            activityData: activityData === undefined ? [] : activityData,
            showCameraWindow: false,
            webCamFields: {},
        }


        return { displayData, metaFieldData }

    } catch (error) {
        console.log({
            "verbError": error
        })
    }
};
