import { takeLatest, put, call } from 'redux-saga/effects'
import * as constants from '../constants'
import network from '../../utils/network'
import { getEndpointURL } from '../../utils/endpoint'
import * as actions from './actions'
import GET_API_KEY from "../../utils/getApiKey"



 function* getStoriesData(args) {
     const API_KEY= GET_API_KEY()
     yield put(actions.setStory([]));

    let { position, sound } = args;
    try {
        const action = getEndpointURL('STORY_CONTENT')
        const params = {
            headers: {
                Authorization: 'Bearer ' + API_KEY,
            }
        }
        let queryParams = `sound=${sound}`;
        const { body } = yield call(network.getData, action, params, queryParams );
        yield put(actions.setStory(body))
    } catch (error) {
        if (error.message.indexOf("401") >= 0) {
            localStorage.clear()
            window.location.replace(process.env.REACT_APP_PAGE_BACKEND + 'login')
        }
        // yield put(actions.getChildrenError({ error }))
    }
}

export default function* () {
    yield takeLatest(
        constants.GET_STORIES_DATA,
        getStoriesData
    )
}
