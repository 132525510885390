import {
    GET_SOCIAL_QUEST_DATA, SET_SOCIAL_QUEST_DATA
} from '../constants';

const getSocialQuestStateAction = () => {
    return {
        type: GET_SOCIAL_QUEST_DATA,
    }
}

const setSocialQuestStateAction = (payload) => {
    return {
        type: SET_SOCIAL_QUEST_DATA,
        payload
    }
}

export {
    getSocialQuestStateAction,
    setSocialQuestStateAction,
}