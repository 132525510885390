import {
    SET_ADJECTIVE_REMIX_DATA,
} from '../constants'

export const initialState = {
    adjectiveState: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_ADJECTIVE_REMIX_DATA: {
            return {
                adjectiveRemixState: action.payload,
            }
        }
        default:
            return state
    }
}
