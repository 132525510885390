import axios from '../services/axios'

export function getAuthToken() {
    console.log('variable values', process.env.REACT_APP_BASE_URL_API, process.env.REACT_APP_PAGE_BACKEND  )
    let location = window.location
    if (
        location.search.includes('?authToken') ||
        localStorage.getItem('authToken') ||
        process.env.NODE_ENV === 'development'
    ) {
        const setToken = location.search.includes('?authToken=')
            ? location.search.split('?authToken=')[1]
            : localStorage.getItem('authToken')
        axios.defaults.headers.common['Authorization'] = `Bearer ${setToken}`
        localStorage.setItem('authToken', setToken)
        if (location.search.includes('?authToken')) {
            window.history.replaceState(null, null, window.location.pathname)
        }
        return false
    } else {
        window.location.replace(process.env.REACT_APP_PAGE_BACKEND)
        return true
    }
}
