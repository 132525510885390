import {
    GET_CHILDREN_SUCCESS,
    SET_CHILDREN_SELECTED,
    SET_CHILDREN_GRADES,
} from '../constants'
export const initialState = {
    children: [],
    loading: true,
    childrenSelected: [],
    grades: null,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CHILDREN_SUCCESS: {
            return {
                children: action.payload,
                loading: false,
                grades: state.grades,
            }
        }
        case SET_CHILDREN_SELECTED: {
            return { ...state, childrenSelected: action.payload }
        }
        case SET_CHILDREN_GRADES: {
            return {
                ...state,
                grades: { all: 'All', ...action.payload },
                loading: false,
            }
        }
        default:
            return state
    }
}
