import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { getEndpointURL } from '../../../utils/endpoint'
import GET_API_KEY from '../../../utils/getApiKey'
import axios from '../../../services/axios'
import './styles.scss'

const Index = ({ open, handleClose }) => {
    const [message, setMessage] = useState(null)
    const [loading, setLoading] = useState(false)

    const submitForm = () => {
        const action = getEndpointURL('CUSTOMER_SUPPORT')
        const API_KEY = GET_API_KEY()
        const params = {
            headers: {
                Authorization: 'Bearer ' + API_KEY,
            }
        }

        setLoading(true)
        axios.post(action, { message }, params)
            .then((response) => {
                setLoading(false)
                handleClose()
                setMessage(null)
                notify()
            }, (error) => {
                setLoading(false)
                if (error.message.indexOf("401") >= 0) {
                    localStorage.clear()
                    window.location.replace(process.env.REACT_APP_PAGE_BACKEND + 'login')
                }
            });
    }

    const notify = () => toast.success('Thans! Feedback has been sent.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
    });

    return (
        <div className={`customer-support-modal modal ${!!open ? 'show d-block' : 'fade'}`}>
            <div className="modal-dialog modal-dialog-2 modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header text-center">
                        <h5 className="modal-title md-c1 text-white" >Contact Support</h5>
                        <button type="button" className="close text-white" onClick={handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 py-0">
                        <div className="modal-d modal-w">
                            <textarea
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder='Type your message here..'
                            ></textarea>
                            <button onClick={submitForm} className='btn' disabled={loading || !message}>{loading ? 'Sending..' : 'Send'}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;