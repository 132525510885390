import React from 'react'
import './style.css'
const AvatarChild = ({ avatar }) => {
    if(!avatar) return <div/>
    const { accesories, body, eyes, hairs, lips, skin } = avatar

    return (
        <div className="avatar-main-img">
            {body && body.trim() !== '' && (
                <img src={body} className="av-img-1" alt="body" />
            )}
            {skin && skin.trim() !== '' && (
                <img src={skin} className="av-img-1" alt="skin" />
            )}
            {lips && lips.trim() !== '' && (
                <img src={lips} className="av-img-1" alt="lips" />
            )}
            {eyes && eyes.trim() !== '' && (
                <img src={eyes} className="av-img-1" alt="eyes" />
            )}
            {hairs && hairs.trim() !== '' && (
                <img src={hairs} className="av-img-1" alt="hairs" />
            )}
            {accesories && accesories.trim() !== '' && (
                <img src={accesories} className="av-img-1" alt="accessories" />
            )}

        </div>
    )
}

export default AvatarChild
