import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { onChangeChildren } from '../../../store/gamesSelection/actions'
import AvatarChild from '../../common/AvatarChild/AvatarChild'
import './styles.scss'

const itemsOptions = [2, 3, 4, 5]

const Index = ({ handleClose, sessionId }) => {
    const dispatch = useDispatch()
    const { childSelected, childrenWithSelection } = useSelector((state) => state.gamesSelection)
    const [screenItems, setScreenItems] = useState(5)
    const [childData, setChildData] = useState(null)
    const [displayText, setDisplayText] = useState(true)
    const [increaseDifficulty, setIncreaseDifficulty] = useState(true)
    const [autoNext, setAutoNext] = useState(true)
    const [imageItems, setImageItems] = useState(itemsOptions)

    useEffect(async () => {
        const activeChildData = childrenWithSelection[sessionId]?.filter((el) => el.child.studentId === childSelected.studentId)
        if (activeChildData && activeChildData.length > 0) {
            if (activeChildData[0].resultToShow) {
                const { resultToShow } = activeChildData[0]
                if (['15', '18', '20', '22', '27'].includes(resultToShow[0].level)) {
                    setImageItems([3, 4, 5])
                }
            }
            if (activeChildData[0].metaFields.settings) {
                const { settings } = activeChildData[0].metaFields

                if (settings) {
                    const { screenItems, displayText, increaseDifficulty, autoNext } = settings
                    setScreenItems(screenItems)
                    setDisplayText(displayText)
                    setIncreaseDifficulty(increaseDifficulty)
                    setAutoNext(autoNext)
                } else {
                    setScreenItems(5)
                    setDisplayText(false)
                    setIncreaseDifficulty(true)
                    setAutoNext(true)
                }
            }
            setChildData(activeChildData[0])
        }
    }, [childrenWithSelection, childSelected])

    const handleOnClose = async () => {
        const settings = {
            screenItems, displayText, increaseDifficulty, autoNext
        }
        const data = { ...childData, previousChild: childSelected, sessionId, metaFields: { ...childData.metaFields, settings } }
        await dispatch(onChangeChildren(data))
        handleClose()
    }

    return (
        <div className={`following-direction-modal modal show d-block`}>
            <div className="modal-dialog modal-dialog-2 modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header text-center">
                        <h5 className="modal-title md-c1 text-white" >Settings</h5>
                        <button type="button" className="close text-white" onClick={handleOnClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 py-0">
                        <div className="user-1">
                            <AvatarChild avatar={childSelected?.avatar} />
                            <span>{childSelected.studentName}</span>
                        </div>
                        <div className="px-2">
                            <div className="divider-1"></div>
                        </div>
                        <div className="modal-d modal-w">
                            <div className="item-box">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="text-left">
                                        <p className="mb-0">Display text with direction</p>
                                    </div>
                                    <div className="">
                                        <input
                                            type="checkbox"
                                            checked={!displayText}
                                            onChange={(e) => setDisplayText(!e.target.checked)}
                                            id="displayText"
                                            hidden />
                                        <label className="switch" for="displayText"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="item-box">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="text-left">
                                        <p className="mb-0">Increase difficulty with success</p>
                                    </div>
                                    <div className="item-numbers">
                                        <input
                                            type="checkbox"
                                            checked={!increaseDifficulty}
                                            onChange={(e) => setIncreaseDifficulty(!e.target.checked)}
                                            id="increaseDifficulty"
                                            hidden />
                                        <label className="switch" for="increaseDifficulty"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="item-box">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="text-left">
                                        <p className="mb-0">Automatically go to next</p>
                                    </div>
                                    <div className="item-numbers">
                                        <input
                                            type="checkbox"
                                            checked={!autoNext}
                                            onChange={(e) => setAutoNext(!e.target.checked)}
                                            id="autoNext"
                                            hidden />
                                        <label className="switch" for="autoNext"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="item-box">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="text-left">
                                        <p className="mb-0">Number of Images</p>
                                    </div>
                                    <div className="item-numbers">
                                        {imageItems.map((item) => (
                                            <span key={item} onClick={() => setScreenItems(item)} className={`${screenItems === item ? 'active' : ''}`}>{item}</span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <p className="text-muted text-left">If you choose to increase level of difficulty, the number of images will increase automatically.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;