import {
    SET_CHILD_SELECTED,
    SET_CHILDREN_WITH_SELECTION_SUCCESS,
    SET_SELECTIONS_SELECTED,
    SET_LOADING,
    SET_GAME_DATA,
    ON_GAME_RESET,
    TOGGLE_WIDGETS,
    GET_ALL_GAME,
    GET_ALL_GAME_SUCCESS,
    GET_GAME_CATEGORIES,
    GET_GAME_CATEGORIES_SUCCESS,
    SET_SETTINGS,
    GET_GAME_LANGUAGES,
    GET_GAME_LANGUAGES_SUCCESS
} from '../constants'

export const initialState = {
    loading: true,
    childSelected: [],
    childrenWithSelection: {},
    selectionsSelected: [],
    currentGameData: { apiData: null, categoryData: null },
    showWidgets: false,
    allGames: [],
    gameCategories: [],
    gameLanguages: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_LOADING: {
            return { ...state, loading: action.payload.loading }
        }
        case SET_CHILD_SELECTED: {
            return { ...state, childSelected: action.payload }
        }
        case SET_SELECTIONS_SELECTED: {
            return { ...state, selectionsSelected: action.payload }
        }
        case SET_CHILDREN_WITH_SELECTION_SUCCESS: {
            return { ...state, childrenWithSelection: action.payload }
        }
        case SET_GAME_DATA: {
            return { ...state, currentGameData: action.payload }
        }
        case ON_GAME_RESET: {
            return { ...initialState }
        }
        case TOGGLE_WIDGETS: {
            return { ...state, ...action.payload }
        }
        case SET_SETTINGS: {
            return { ...state, ...action.payload }
        }
        case GET_ALL_GAME: {
            return { ...state }
        }
        case GET_ALL_GAME_SUCCESS: {
            return { ...state, allGames: action.payload }
        }
        case GET_GAME_CATEGORIES: {
            return { ...state }
        }
        case GET_GAME_CATEGORIES_SUCCESS: {
            return { ...state, gameCategories: action.payload }
        }
        case GET_GAME_LANGUAGES: {
            return { ...state }
        }
        case GET_GAME_LANGUAGES_SUCCESS: {
            return { ...state, gameLanguages: action.payload }
        }
        default:
            return state
    }
}
