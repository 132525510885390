import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as constants from '../constants'
import * as actions from './actions'
import network from '../../utils/network'
import { getEndpointURL } from '../../utils/endpoint'
import GET_API_KEY from '../../utils/getApiKey'

function* handleGetChildren() {
    const API_KEY = GET_API_KEY()
    const languages = [
        {
            id: 1,
            lang: 'en',
            language: 'English',
        },
        {
            id: 2,
            lang: 'sp',
            language: 'Spanish',
        },
        {
            id: 3,
            lang: 'pt',
            language: 'Portuguese',
        },
        {
            id: 4,
            lang: 'ab',
            language: 'Arabic',
        },
        {
            id: 5,
            lang: 'nl',
            language: 'Dutch',
        },
        {
            id: 6,
            lang: 'fr',
            language: 'French',
        },
        {
            id: 7,
            lang: 'de',
            language: 'German',
        },
        {
            id: 8,
            lang: 'hi',
            language: 'Hindi',
        },
        {
            id: 9,
            lang: 'it',
            language: 'Italian',
        },
        {
            id: 10,
            lang: 'vi',
            language: 'Vietnamese',
        },
    ]

    try {
        const action = getEndpointURL('THERAPIST_STUDENTS')
        const params = {
            headers: {
                Authorization: 'Bearer ' + API_KEY,
            },
        }

        const { body } = yield call(network.getData, action, params)

        const children = body.map((child, index) => {
            return {
                studentId: child.id,
                studentName: child.first_name + ' ' + child.last_name,
                studentImg: '/assets/images/male-2.png',
                grade: child.grade,
                gender: child.gender,
                avatar: child.avatar,
                goals: child.goals,
                is_bilingual: child.is_bilingual,
                delivery_language: child.delivery_language,
                language: child.delivery_language
                    ? languages.filter(
                          (l) => l.language === child.delivery_language
                      )[0]?.lang
                    : 'en',
                userId: child.user_id,
            }
        })
        children.sort((a, b) => {
            const nameA = a.studentName.toLowerCase()
            const nameB = b.studentName.toLowerCase()

            if (nameA < nameB) return -1
            if (nameA > nameB) return 1
            return 0 //no sorting
        })
        yield put(actions.getChildrenSuccess(children))
    } catch (error) {
        if (error.message.indexOf('401') >= 0) {
            localStorage.clear()
            window.location.replace(
                process.env.REACT_APP_PAGE_BACKEND + 'login'
            )
        }
        yield put(actions.getChildrenError({ error }))
    }
}

function* handleGetChildrenGrades() {
    const API_KEY = GET_API_KEY()

    try {
        const action = getEndpointURL('GRADES')
        const params = {
            headers: {
                Authorization: 'Bearer ' + API_KEY,
            },
        }
        const { body } = yield call(network.getData, action, params)
        yield put(actions.setChildrenGrades(body))
    } catch (error) {
        if (error.message.indexOf('401') >= 0) {
            localStorage.clear()
            window.location.replace(
                process.env.REACT_APP_PAGE_BACKEND + 'login'
            )
        }
        yield put(actions.getChildrenError({ error }))
    }
}

export default function* () {
    yield all([
        takeEvery(constants.GET_CHILDREN, handleGetChildren),
        takeEvery(constants.GET_CHILDREN_GRADES, handleGetChildrenGrades),
    ])
}
