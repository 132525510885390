import { put, select } from 'redux-saga/effects'
import { selectGameAPIData } from '../selectors'
import * as actions from '../actions'
import { randomizeArray } from '../../../utils/util';


export function* getSequencingData(payload, getGameData) {
    const { from, steps } = payload;

    let sequencingState = yield select(selectGameAPIData)

    if (!sequencingState) {
        sequencingState = yield* getGameData(
            null,
            null,
            null,
            'SEQUENCING',
            from,
            null,
            null
        )

        yield put(actions.setGameData({ apiData: sequencingState }))
    }

    let resultItems = []

    sequencingState.filter(function (item) {
        if (steps === 3) {
            return item.step4 === null && item.step5 === null
        } else if (steps === 4) {
            return item.step4 !== null && item.step5 === null
        } else {
            return item.step4 !== null && item.step5 !== null
        }
    }).forEach((item) => {
        resultItems.push({
            id: item.id,
            audio: item.audio,
            audio_path: item.audio_path,
            gamelevel: item.gamelevel,
            question: item.question,
            steps: randomizeArray([
                ...[...Array.from({ length: steps }, (v, index) => {
                    return (
                        ({ id: index + 1, step: item[`step${index + 1}`] })
                    )
                }
                )],
            ])
        })
    })
    const displayData = randomizeArray(resultItems)
    const metaFieldData = {
        activeItemIndex: 0,
        gameData: displayData,
        droppedAnswer: [],
        invisibleAnswerIds: [],
        questionsAnswered: [],
    }
    return { displayData, metaFieldData }
}