import React, { useState, useEffect, useRef } from 'react'
// import axios from 'axios'
import TimeAgo from 'react-timeago'
import { useSelector } from 'react-redux'
import Pusher from 'pusher-js'
import Loader from '../../common/loader'
import useOnClickOutside from '../../../hooks/use-onclick-outside'
import GET_API_KEY from '../../../utils/getApiKey'
import useIntervalHook from '../../../hooks/use-interval-hook'
import { NOTIFICATIONS } from '../../../utils/endpoint'
import axios from '../../../services/axios'
import './styles.scss'

const markAsSeen = (id) => {
    var data = JSON.stringify({
        id: id,
    })
    const headers = {
        Authorization: 'Bearer ' + GET_API_KEY(),
        'Content-Type': 'application/json',
    }

    var config = {
        method: 'post',
        url: 'https://staging.app.smartyearslearning.com/api/notification/read',
        headers,
        data,
    }

    axios(config)
        .then(function (response) {
            console.log(JSON.stringify(response.data))
        })
        .catch(function (error) {
            console.log(error)
        })
}

const Index = ({
    show,
    handleClose,
    notificationCount,
    style,
    loginTypeFlag,
}) => {
    const UserDetails = useSelector((state) => state.auth)
    const [data, setData] = useState(null)
    // const ref = useRef();
    useEffect(() => {
        fetchNotification()
    }, [])
    useEffect(() => {
        if (data) {
            const getUnseenNotification = data.filter(
                (dt) => dt.read_at == null
            )
            notificationCount(getUnseenNotification.length)
        }
    }, [data])
    useIntervalHook(() => {
        fetchNotification()
    }, 1000 * 60)

    useEffect(() => {
        if (UserDetails.user?.user_id) {
            const pusher = new Pusher('5dd52732b1fa14e14bdd', {
                cluster: 'mt1',
            })
            const channel = pusher.subscribe(
                `new-game-${UserDetails.user?.user_id}`
            )
            channel.bind('new-game', (data) => {
                console.log({ data })
            })
        }
    })
    const fetchNotification = () => {
        const API_KEY = GET_API_KEY()
        const params = {
            headers: {
                Authorization: 'Bearer ' + API_KEY,
            },
        }
        //setIsLoading(true)
        axios.get(NOTIFICATIONS, params).then(
            (response) => {
                setData(response.body)
            },
            (error) => {
                if (error.message.indexOf('401') >= 0) {
                    localStorage.clear()
                    window.location.replace(
                        process.env.REACT_APP_PAGE_BACKEND + 'login'
                    )
                }
            }
        )
    }

    const handleNotificationClick = (id) => {
        console.log('id', id)
        if (id) {
            const copy = [...data]
            markAsSeen(id)
            const index = data.findIndex((data) => data.id === id)
            copy[index].read_at = '123'
            setData(copy)
        } else if (!window.location.pathname.includes('staging')) {
            window.open(
                `https://staging.app.smartyearslearning.com/notices`,
                '_blank'
            )
        } else {
            window.open(`https://app.smartyearslearning.com/notices`, '_blank')
        }
    }

    return (
        <div
            style={style}
            onMouseDown={(e) => e.stopPropagation()}
            className={`notifications-container ${show ? 'fadeIn' : 'fadeOut'}`}
        >
            <span className="corner"></span>
            <div className="notification-in">
                {data ? (
                    data.map((dt) => (
                        <div
                            className="notification"
                            key={dt.id}
                            onClick={() => handleNotificationClick(dt.id)}
                        >
                            <div
                                className={`dot ${
                                    dt.read_at ? '' : 'not-seen'
                                }`}
                            ></div>
                            <div className="notification-info">
                                <h3
                                    dangerouslySetInnerHTML={{
                                        __html: dt.data,
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                ></h3>
                                <div className="notification-timer">
                                    <TimeAgo date={dt.created_at} />
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <Loader />
                )}
            </div>
            {loginTypeFlag && (
                <a
                    className="btn-view-all"
                    onClick={() => handleNotificationClick(null)}
                >
                    See All Notifications
                </a>
            )}
        </div>
    )
}

export default Index
