import { all, takeEvery, put, select } from 'redux-saga/effects'
import * as constants from '../constants'
import * as actions from './actions'
import { selectedGameSettings } from '../settingSec/selectors'

function* handleSettingsChange({ payload }) {
    try {
        const { currentGame, gameSettingValues } = payload;
        const gameSettingsData = yield select(selectedGameSettings)
        gameSettingsData[currentGame] =gameSettingValues;
         yield put(actions.setGameSettings(gameSettingsData))
    } catch (error) {
        console.log({ error })
    }
}

export default function* () {
    yield all([
        takeEvery(constants.CHANGE_GAME_SETTINGS, handleSettingsChange),
    ])
}
