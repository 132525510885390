import {
    GET_ADJECTIVE_REMIX_DATA, SET_ADJECTIVE_REMIX_DATA
} from '../constants';

const getAdjectiveRemixStateAction = (payload) => {
    return {
        type: GET_ADJECTIVE_REMIX_DATA,
        payload
    }
}

const setAdjectiveRemixStateAction = (payload) => {
    return {
        type: SET_ADJECTIVE_REMIX_DATA,
        payload
    }
}

export {
    getAdjectiveRemixStateAction,
    setAdjectiveRemixStateAction
}