import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_API,
})


instance.interceptors.response.use((response) => {
    if (response.status === 401) {
        localStorage.clear()
        window.location.replace(process.env.REACT_APP_PAGE_BACKEND + 'login')
    } else {
        return response.data
    }
}
)
export default instance
