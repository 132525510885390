import { put, select } from 'redux-saga/effects'
import { selectGameAPIData } from '../selectors'
import * as actions from '../actions'
import { randomizeArray } from '../../../utils/util';


export function* getGroceryData(payload, getGameData) {
    const { from, selectedSyllabes, screens } = payload;
    let groceryState = yield select(selectGameAPIData)

    if (!groceryState) {
        const gymData = yield* getGameData(
            null,
            null,
            null,
            'GYM',
            from,
            null,
            null
        )
        const groceryData = yield* getGameData(
            null,
            null,
            null,
            'STORE',
            from,
            null,
            null
        )
        groceryState = { gymData: gymData.questions, groceryData: groceryData.questions };

        yield put(actions.setGameData({ apiData: groceryState }))

    }
    let resultItems = [];

    groceryState.gymData.forEach((item) => {
        let answerData = [];
        let correctAnswer = { id: item.id + '1', isVisible: true, word: item.correct }

        answerData.push(correctAnswer)
        for (let i = 1; i <= screens; i++) {
            answerData.push(
                { id: item.id + i, isVisible: true, word: item[`level${i}`] },
            )

        }
        resultItems.push({
            id: item.id,
            imagePath: item.image_path,
            image: item.image,
            answers: randomizeArray(answerData),
            sentence: item.question,
            correct: item.correct,
            sound: item.sound,
            sound_path: item.sound_path,
            flag: 'gym'
        })

    })

    groceryState.groceryData.forEach((item) => {
        let answerData = [];
        let correctAnswer = { id: item.id + '1', isVisible: true, word: item.correct }

        answerData.push(correctAnswer)

        answerData.push(
            { id: item.id, isVisible: true, word: item.foil },
        )
        resultItems.push({
            id: item.id,
            imagePath: item.image_path,
            image: item.image,
            answers: randomizeArray(answerData),
            sentence: item.question,
            correct: item.correct,
            sound: item.sound,
            sound_path: item.sound_path,
            flag: 'grocery'
        })

    })
    if (selectedSyllabes.length === 1 && selectedSyllabes.includes(1)) {
        resultItems = resultItems.filter((item) => item.flag === 'grocery')
    } else if (selectedSyllabes.length === 1 && selectedSyllabes.includes(2)) {
        resultItems = resultItems.filter((item) => item.flag === 'gym')
    }
    const displayData = randomizeArray(resultItems)
    const metaFieldData = {
        activeItemIndex: 0,
        gameData: displayData,
        droppedAnswer: [],
        invisibleAnswerIds: [],
        questionsAnswered: [],
        activeAnswers: displayData[0].answers,
        correctAnswerCount: 0,
        activeType: 'receptive'
    }
    return { displayData, metaFieldData }
}