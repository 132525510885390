import { select } from 'redux-saga/effects'
import { randomizeArray } from '../../../utils/util';
import { selectGameAPIData } from '../selectors'
export function* getWouldRatherData(payload, getGameData) {
    const {
        from,
    } = payload
    let WouldRatherState = yield select(selectGameAPIData)
    const resultItems = []
    if (!WouldRatherState) {
        WouldRatherState = yield* getGameData(
            null,
            null,
            null,
            'WOULD_YOU_RATHER',
            from,
            null
        )
    }
    const languageData = payload.language === "sp" ? WouldRatherState.questions_sp : WouldRatherState.questions_en 
    languageData.forEach((item) => {
        let answerData = [];

        for (let i = 0; i < 2; i++) {
            answerData.push(
                { id: item.answers[i].id + i, isVisible: true, answerAudio: item.answers[i].audio, is_correct: item.answers[i].is_correct, text: item.answers[i].text },
            )
        }
        resultItems.push({
            id: item.id,
            imagePath: WouldRatherState.image_files_path,
            answers: randomizeArray(answerData),
            audioPath: WouldRatherState.audio_files_path,
            question: item.text,
            audio: item.audio,
            questionId: item.id,
            correct: item.answers[0].id,
        })

    })


    const displayData = randomizeArray(resultItems)
    const metaFieldData = {
        questionIndex: 0,
        correctAnswerCount: 0,
        questionsAnswered: [],
    }

    return { displayData, metaFieldData }

};
