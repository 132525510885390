import axios from 'axios'
import { BASE_URL } from './environment'

axios.interceptors.response.use((response) => {
    if (response.status === 401) {
        localStorage.clear()
        window.location.replace(process.env.REACT_APP_PAGE_BACKEND + 'login')
    } else {
        return response.data
    }
})

function networkService() {
    const baseUrl = BASE_URL

    function* postData(action, bodyPost, params) {
        //This is for call endpoint for save json
        const url = baseUrl + action
        return yield axios.post(url, bodyPost, params)
    }

    function* getData(action, params, queryData) {
        let url = `${
            action.includes('admin/')
                ? 'https://app.smartyearslearning.com/api/' +
                  action.replace('admin/', '')
                : baseUrl + action
        }`
        if (queryData) {
            url = `${url}?${queryData}`
        }
        const config = params
        return yield axios.get(url, config)
    }

    function* putData(action, body) {
        const url = baseUrl + action
        return yield axios.put(url, body)
    }

    function* deleteData(action, params) {
        const url = baseUrl + action
        const config = { params }
        return yield axios.delete(url, config)
    }

    return {
        postData,
        putData,
        getData,
        deleteData,
    }
}

const network = networkService()
export default network
