import { GET_USERS_SUCCESS } from '../constants'
export const initialState = {
    user: [],
    loading: true,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_USERS_SUCCESS: {
            return {
                ...state,
                user: action.payload,
                loading: false,
            }
        }
        default:
            return state
    }
}
