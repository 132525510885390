import { act } from 'react-dom/test-utils'
import {
    SET_STORY_DATA,
} from '../constants'
export const initialState = {
    loading: true,
    story: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_STORY_DATA: {
            return {
                story: action.payload,
            }
        }
        default:
            return state
    }
}
