import { put, select } from 'redux-saga/effects'
import { selectGameAPIData } from '../selectors'
import * as actions from '../actions'
import { randomizeArray } from '../../../utils/util';


export function* getRhymingMatchData(payload, getGameData) {
    const { from } = payload;

    let rhymingState = yield select(selectGameAPIData)

    if (!rhymingState) {
        rhymingState = yield* getGameData(
            null,
            null,
            null,
            'RHYMING_MATCH',
            from,
            null,
            null
        )
        yield put(actions.setGameData({ apiData: rhymingState }))
    }


    let resultItems = [];

    rhymingState.map((item, idx) => {
        let answerData = [];

        let correctAnswer = []
        for (let i = 1; i < 5; i++) {
            answerData.push(
                { id: item.id + i, isVisible: true, answerImage: item[`correct${i}_image`], answerAudio: item[`correct${i}_sound`], word: item[`correct${i}`], answerSyllable: item[`correct${i}`] },
            )
            correctAnswer.push(
                item[`correct${i}`] ,
            )
        }

        for (let i = 1; i < 4; i++) {
            answerData.push(
                { id: item.id + 't' + i, isVisible: true, answerImage: item[`wrong${i}_image`], answerAudio: item[`wrong${i}_sound`], word: item[`wrong${i}`], answerSyllable: item[`wrong${i}`] },
            )
        }

        return (
            resultItems.push({
                id: item.id,
                audioPath: item.audio_path,
                imagePath: item.image_path,
                correctAnswer: correctAnswer,
                currentItemSyllable: item.correct,
                answers: randomizeArray(answerData)
            })
        )
    })



    const displayData = randomizeArray(resultItems)
    const metaFieldData = {
        activeItemIndex: 0,
        gameData: displayData,
        droppedAnswer: [],
        invisibleAnswerIds: [],
        questionsAnswered: [],
        activeAnswers: displayData[0].answers,
        correctAnswerCount: 0,
    }
    return { displayData, metaFieldData }
}