import { put, select } from 'redux-saga/effects'
import { randomizeArray } from '../../../utils/util';
import { selectAdjectiveRemixState } from '../../adjectiveRemix/selectors';
import * as prepositionActions from '../../preposition/actions';
import { selectPrepositionState } from '../../preposition/selectors'

export function* getPrepositionData(payload, getGameData) {
    const { unSelectedLevels, unSelectedItems, activityData, from, level } = payload
    let prepositionState = yield select(selectPrepositionState)
    let displayData;
    if (!prepositionState) {
        const prepositionData = yield* getGameData(
            null,
            null,
            level,
            'PREPOSITION',
            from,
            null,
            null
        )

        const levels = {}

        prepositionData.map((prepositionItem) => {
            const levelName = prepositionItem.level
            if (!levels[levelName]) {
                levels[levelName] = {
                    prepositions: [],
                    questions: [],
                }
            }
            if (
                !levels[levelName].prepositions.includes(
                    prepositionItem.preposition
                )
            ) {
                levels[levelName].prepositions.push(
                    prepositionItem.preposition
                )
            }
            prepositionItem.items.map((item) => {
                let [prevText, nextText] = item.group.split('_')
                levels[levelName].questions.push({
                    prepositionId: prepositionItem.id,
                    group: prepositionItem.group,
                    questionPart1: prevText,
                    questionPart2: nextText,
                    audio: item.audio,
                    image: item.image,
                    preposition: item.preposition,
                    wrong1: item.wrong1,
                    wrong2: item.wrong2,
                    id: item.id,
                    answers: randomizeArray([
                        {
                            id: item.id + `1`,
                            isVisible: true,
                            answerText: item.preposition,
                            answerAudio: item.preposition_audio,
                        },
                        {
                            id: item.id + `2`,
                            isVisible: true,
                            answerText: item.wrong1,
                            answerAudio: item.wrong1_audio,
                        },
                        {
                            id: item.id + `3`,
                            isVisible: true,
                            answerText: item.wrong2,
                            answerAudio: item.wrong2_audio,
                        },
                    ]),
                })
            })
        })

        const resultItems = []
        for (let levelName in levels) {
            resultItems.push({
                level: levelName,
                prepositions: levels[levelName].prepositions,
                items: randomizeArray(levels[levelName].questions),
            })
        }
        prepositionState = resultItems
        yield put(
            prepositionActions.setPrepositionStateAction(resultItems)
        )
    }

    let filteredData = []

    let newUnselectedItems = {};

    if (Object.keys(unSelectedItems).length > 0) {
        for (let key in unSelectedItems) {
            for (let i = 0; i < unSelectedItems[key].length; i++) {
                let finalUnSelectedItems = newUnselectedItems[key] || []
                if (unSelectedItems[key][i].includes("/")) {
                    let [prevText, nextText] = unSelectedItems[key][i].split('/')
                    newUnselectedItems[key] = [...finalUnSelectedItems, prevText, nextText]
                } else {
                    newUnselectedItems[key] = [...finalUnSelectedItems, unSelectedItems[key][i]]
                }
            }
        }
    }

    if (unSelectedLevels && unSelectedLevels.length > 0) {
        prepositionState.map((levels) => {
            if (!unSelectedLevels.includes(levels.level)) {
                filteredData.push({
                    ...levels,
                    items: levels.items.filter(
                        (item) =>
                            !newUnselectedItems[`Level ${levels.level}`] ||
                            !newUnselectedItems[
                                `Level ${levels.level}`
                            ].includes(item.preposition)
                    ),
                })
            }
        })
        displayData = filteredData;
    } else {
        displayData = prepositionState
    }
    displayData = displayData.map((category) => {
        return { ...category, items: randomizeArray(category.items) }
    })
    const metaFieldData = {
        currentLevel: 1,
        activeItemIndex: 0,
        gameType: 1,
        receptiveCorrectAnswerCount: 0,
        expressiveCorrectAnswerCount: 0,
        activeAnswers: displayData[0].answers,
        showCongratsScreen: false,
        nextLevel: 0,
        showCorrectAnswer: false,
        activityData: activityData || []
    }

    return { displayData, metaFieldData }

};
