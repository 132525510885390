import { put, select } from 'redux-saga/effects'
import { selectGameAPIData } from '../selectors'
import * as actions from '../actions'
import { randomizeArray } from '../../../utils/util';


export function* getInferencingData(payload, getGameData) {
    const { from } = payload;

    let inferencingState = yield select(selectGameAPIData)

    if (!inferencingState) {
        inferencingState = yield* getGameData(
            null,
            null,
            null,
            'INFERENCING',
            from,
            null,
            null
        )
        yield put(actions.setGameData({ apiData: inferencingState }))
    }
    const data = randomizeArray(JSON.parse(JSON.stringify(inferencingState)))
    let displayData = [];
    data.map((item, idx) => {
        return (
            displayData.push({
                ...item,
                answers: randomizeArray([
                    {
                        id: item.id + '1',
                        isVisible: true,
                        isAnswered: false,
                        answerText: item.correct,
                    },
                    {
                        id: item.id + '2',
                        isVisible: true,
                        isAnswered: false,
                        answerText: item.wrong1,
                    },
                    {
                        id: item.id + '3',
                        isVisible: true,
                        isAnswered: false,
                        answerText: item.wrong2,
                    }
                ])
            })
        )
    })
    const metaFieldData = {
        receptiveActiveItemIndex: 0,
        expressiveActiveItemIndex: 0,
        gameType: 1,
        activeAnswers: displayData[0].answers,
        questionsAnswered: [],
    }
    return { displayData, metaFieldData }
}