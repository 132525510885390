import React from 'react'
import { useState } from 'react'
import { AudioContext } from './AudioContext'

export const AudioContextProvider = ({ children }) => {
    const [audioData, setAudioData] = useState({})
    return (
        <AudioContext.Provider value={{ audioData, setAudioData }}>
            {children}
        </AudioContext.Provider>
    )
}
