import { lazy } from 'react'

const HomePage = lazy(() => import('./pages/Home'))
const Games = lazy(() => import('./pages/Games'))
const ActivitySelectedPage = lazy(() => import('./pages/ActivitySelectedPage'))
const NotFound = lazy(() => import('./components/common/PageNotFound'))
const StudentActivityPage = lazy(() => import('./pages/StudentActivityPage'))
const routes = [
    { path: '/', exact: true, component: HomePage },
    { path: '/select-activity', exact: true, component: Games },
    { path: '/student-activity', exact: true, component: StudentActivityPage },
    {
        path: '/activity/:sessionId/:activityGame',
        exact: true,
        component: ActivitySelectedPage,
    },
    { path: '/404', exact: false, component: NotFound },
    { path: '*', exact: false, component: NotFound },
]

export default routes
