import { put, select } from 'redux-saga/effects'
import { selectGameAPIData } from '../selectors'
import * as actions from '../actions'
import { randomizeArray } from '../../../utils/util';


export function* getWhichData(payload, getGameData) {
    const { from, language } = payload;
    let whichState = yield select(selectGameAPIData)

    if (!whichState) {
        whichState = yield* getGameData(
            null,
            null,
            null,
            'WHICH',
            from,
            null,
            null
        )
        yield put(actions.setGameData({ apiData: whichState }))
    }
    const data = randomizeArray(JSON.parse(JSON.stringify(whichState)))
    let displayData = [];
    data.map((item, idx) => {
        return (
            displayData.push({
                ...item,
                answers: randomizeArray([
                    {
                        id: item.id + '1',
                        isVisible: true,
                        isAnswered: false,
                        answerText: item[`correct_${language}`],
                        image: item.correct_en
                    },
                    {
                        id: item.id + '2',
                        isVisible: true,
                        isAnswered: false,
                        answerText: item[`wrong1_${language}`],
                        image: item.wrong1_en
                    },
                    {
                        id: item.id + '3',
                        isVisible: true,
                        isAnswered: false,
                        answerText: item[`wrong2_${language}`],
                        image: item.wrong2_en
                    }
                ])
            })
        )
    })
    const metaFieldData = {
        activeItemIndex: 0,
        activeAnswers: displayData[0].answers,
        questionsAnswered: [],
    }
    return { displayData, metaFieldData }
}