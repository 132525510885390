import {
    SET_CHILDREN_WITH_SELECTION,
    SET_CHILDREN_WITH_SELECTION_SUCCESS,
    SET_CHILDREN_WITH_SELECTION_ERROR,
    SET_CHILD_SELECTED,
    SET_SELECTIONS_SELECTED,
    SET_LOADING,
    ON_CHILDREN_CHANGE,
    ON_GAME_RESET,
    SET_GAME_DATA,
    SEND_GAME_DATA,
    TOGGLE_WIDGETS,
    GET_ALL_GAME,
    GET_ALL_GAME_SUCCESS,
    GET_ALL_GAME_ERROR,
    GET_GAME_CATEGORIES,
    GET_GAME_CATEGORIES_SUCCESS,
    GET_GAME_CATEGORIES_ERROR,
    SET_SETTINGS,
    GET_GAME_LANGUAGES,
    GET_GAME_LANGUAGES_SUCCESS,
    GET_GAME_LANGUAGES_ERROR
} from '../constants'

export const setSelectionLoading = (payload) => {
    return {
        type: SET_LOADING,
        payload,
    }
}

export const setChildSelected = (payload) => {
    return {
        type: SET_CHILD_SELECTED,
        payload
    }
}

export const setSelectionsSelected = (payload) => {
    return {
        type: SET_SELECTIONS_SELECTED,
        payload,
    }
}

export const setWithSelection = (payload) => ({
    type: SET_CHILDREN_WITH_SELECTION,
    payload,
})

export const setWithSelectionSuccess = (payload) => ({
    type: SET_CHILDREN_WITH_SELECTION_SUCCESS,
    payload,
})

export const setWithSelectionError = (payload) => ({
    type: SET_CHILDREN_WITH_SELECTION_ERROR,
    payload,
})

export const onChangeChildren = (payload) => ({
    type: ON_CHILDREN_CHANGE,
    payload,
})

export const resetGamesStore = (payload) => ({
    type: ON_GAME_RESET,
    payload,
})

export const setGameData = (payload) => ({
    type: SET_GAME_DATA,
    payload,
})

export const sendGameData = (requestData) => {
    return {
        type: SEND_GAME_DATA,
        requestData
    }
}
export const toggleWidgets = (payload) => {
    return {
        type: TOGGLE_WIDGETS,
        payload
    }
}
export const setSettings = (payload) => {
    return {
        type: SET_SETTINGS,
        payload
    }
}

export const getAllGame = () => {
    return {
        type: GET_ALL_GAME,
    }
}

export const getAllGameSuccess = (payload) => ({
    type: GET_ALL_GAME_SUCCESS,
    payload,
})

export const getAllGameError = (payload) => ({
    type: GET_ALL_GAME_ERROR,
    payload,
})

export const getGameCategories = () => {
    return {
        type: GET_GAME_CATEGORIES,
    }
}

export const getGameCategoriesSuccess = (payload) => ({
    type: GET_GAME_CATEGORIES_SUCCESS,
    payload,
})

export const getGameCategoriesError = (payload) => ({
    type: GET_GAME_CATEGORIES_ERROR,
    payload,
})

export const getGameLanguages = () => {
    return {
        type: GET_GAME_LANGUAGES,
    }
}

export const getGameLanguagesSuccess = (payload) => ({
    type: GET_GAME_LANGUAGES_SUCCESS,
    payload,
})

export const getGameLanguagesError = (payload) => ({
    type: GET_GAME_LANGUAGES_ERROR,
    payload,
})

