import {
    SET_WORDS_FARM_DATA,
} from '../constants'
export const initialState = {
    wordsFarmData: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_WORDS_FARM_DATA: {
            return {
                wordsFarmState: action.payload,
            }
        }
        default:
            return state
    }
}
