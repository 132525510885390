import { put, select } from 'redux-saga/effects'
import { selectGameAPIData } from '../selectors'
import * as actions from '../actions'
import { randomizeArray } from '../../../utils/util';


export function* getPictureNamingData(payload, getGameData) {
    let gameData = yield select(selectGameAPIData)
    const { from, unSelectedCategories, language } = payload;
    let resultItems = [];
    if (!gameData) {
        gameData = yield* getGameData(
            null,
            null,
            null,
            'PICTURE_NAMING',
            from,
            null,
            null,
        )

        const categories = []

        gameData.map((item) => {
            let cat = item.category;
            if (!categories.includes(cat)) {
                categories.push(cat);
            }
        })

        yield put(actions.setGameData({ apiData: gameData, categoryData: categories }))
    }

    gameData.map((item) => {
        if (!unSelectedCategories.includes(item.category)) {
            resultItems.push({
                audio: item[`audio_${language}`],
                audio_path: item[`audio_path_${language}`],
                id: item.id,
                image: item.image,
                image_path: item.image_path,
                word: item[`word_${language}`],
                category: item.category
            })
        }
    })
    const displayData = randomizeArray(resultItems);
    const metaFieldData = {
        activeItemIndex: 0
    }

    return { displayData, metaFieldData }

};
