import React, { useEffect, useRef } from 'react'
import './styles.scss'
import { AudioContext } from '../../../store/AudioContext/AudioContext'
import { useContext } from 'react'
import { useState } from 'react'

const AudioPlayer = ({
    audioRefData,
    audioUrl,
    isPlay,
    isWithoutProgress,
    toggleAudioPlay,
    onAudioEnd,
    loop,
}) => {
    const [initital, setInitital] = useState(true)
    const audioRef = useRef()
    let spanRef = useRef()
    let circleRef = useRef()
    let clickRef = useRef()
    let updateBar

    const { audioData, setAudioData } = useContext(AudioContext)
    useEffect(() => {
        console.log('------------audioData', audioData)
        handlePlayAudioStatus(audioData?.isPlay)
    }, [audioData])

    useEffect(() => {
        // Set the loop property of the audio element based on the loop prop
        if (audioRef.current) {
            audioRef.current.loop = loop
        }
    }, [loop])

    useEffect(() => {}, [isPlay])

    const handlePlayAudioStatus = (isPlayAudio) => {
        if (isPlayAudio) {
            audioRef.current.play()
            if (!isWithoutProgress) {
                updateBar = setInterval(handleProgressBar, 500)
            }
        } else {
            audioRef.current.pause()
            if (!isWithoutProgress) {
                window.clearInterval(updateBar)
            }
        }
    }

    useEffect(() => {
        if (isPlay === undefined) {
            return
        }
        handlePlayAudioStatus(isPlay)
    }, [isPlay, audioUrl])

    const onAudioEnded = () => {
        console.log('audioData onAudioEnded', audioData)
        if (
            audioData?.from === 'predicting' ||
            audioData?.from === 'pictureNaming' ||
            audioData?.from === 'pictureMatching' ||
            audioData?.from === 'inferencing' ||
            audioData?.from === 'silly' ||
            audioData?.from === 'fun' ||
            audioData?.from === 'guess' ||
            audioData?.from === 'how' ||
            audioData?.from === 'figurative' ||
            audioData?.from === 'grocery' ||
            audioData?.from === 'baker' ||
            audioData?.from === 'letsOrderIt' ||
            audioData?.from === 'goSequencing'
        ) {
            setAudioData(null)
            return
        }
        if (
            audioData?.from === 'preposition' ||
            audioData?.from === 'letterNames' ||
            audioData?.from === 'divergent' ||
            audioData?.from === 'categoryNaming' ||
            audioData?.from === 'puzzle' ||
            audioData?.from === 'socialQuest' ||
            audioData?.from === 'smartySpell' ||
            audioData?.from === 'talkieTot' ||
            audioData?.from === 'bingo'
        ) {
            if (audioData?.onAudioEnd) {
                audioData?.onAudioEnd()
            }
            return
        }
        if (audioRef.current) {
            audioRef.current.currentTime = 0
        }
        if (onAudioEnd) {
            onAudioEnd(audioRefData)
        }
    }

    const handleProgressBar = () => {
        if (audioRef && audioRef.current) {
            if (!audioRef.current.ended) {
                const barSize = 100
                const size = parseInt(
                    (audioRef.current.currentTime * barSize) /
                        audioRef.current.duration
                )
                spanRef.current.style.width = size + '%'
                circleRef.current.style.left = size + '%'
            } else {
                spanRef.current.style.width = '0%'
                circleRef.current.style.left = '0%'
            }
        }
    }
    const handleBarClick = (e) => {
        console.log('e', e)
        console.log('audioref', audioRef)
        let width = clickRef.current.clientWidth
        const offset = e.nativeEvent.offsetX
        const divprogress = (offset / width) * 100
        audioRef.current.currentTime =
            (divprogress / 100) * audioRef.current.duration
    }
    return (
        <div id="audio-container">
            <audio
                id="audioPlayer1"
                className="audio"
                ref={audioRef}
                src={audioData?.url ? audioData.url : audioUrl}
                // src={'/assets/audio/correct.mp3'} //For testing
                // src={'/assets/audio/where-does-it-go.mp3'} //For testing
                onEnded={() => {
                    onAudioEnded()
                }}
                type="audio/mp3"
                preload="metadata"
            />
            {!isWithoutProgress && (
                <div id="controls">
                    <button
                        type="button"
                        className="togglePlay"
                        id="play-pause"
                        onClick={() => {
                            toggleAudioPlay()
                        }}
                    >
                        <i
                            className={`fas ${isPlay ? 'fa-pause' : 'fa-play'}`}
                        ></i>
                    </button>
                    <div
                        id="custom-seekbar"
                        onClick={(e) => handleBarClick(e)}
                        ref={clickRef}
                    >
                        <span ref={spanRef}></span>
                        <div ref={circleRef}></div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AudioPlayer
