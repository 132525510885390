import { SET_GAME_FILTER } from '../constants'

export const initialState = {
    gameFilter: { value: 'all' },
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_GAME_FILTER: {
            return {
                gameFilter: action.payload,
            }
        }
        default:
            return state
    }
}
