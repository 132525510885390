import { select } from 'redux-saga/effects'
import { randomizeArray } from '../../../utils/util'
import { selectGameAPIData } from '../selectors'
export function* getLetterNameData(payload, getGameData) {
    const { language, from, screens } = payload
    let letterNameState = yield select(selectGameAPIData)
    const resultItems = []
    if (!letterNameState) {
        letterNameState = yield* getGameData(
            null,
            null,
            null,
            'LETTER_NAMES',
            from,
            null,
            language
        )
    }

    letterNameState.questions_en.forEach((item) => {
        let answerData = []
        let correctAnswer = {
            id: item.answers[0].id,
            isVisible: true,
            answerAudio: item.answers[0].audio,
            image: item.answers[0].image,
            is_correct: item.answers[0].is_correct,
        }

        answerData.push(correctAnswer)
        for (let i = 0; i <= screens; i++) {
            answerData.push({
                id: item.answers[i].id + i,
                isVisible: true,
                answerAudio: item.answers[i].audio,
                image: item.answers[i].image,
                is_correct: item.answers[i].is_correct,
            })
            if (i === 0) {
                answerData.pop()
            }
        }
        resultItems.push({
            id: item.id,
            imagePath: letterNameState.image_files_path,
            answers: randomizeArray(answerData),
            audioPath: letterNameState.audio_files_path,
            category: item.category,
            text: item.text,
            audio: item.audio,
            correct: item.answers[0].id,
            questionId: item.id,
        })
    })

    const displayData = randomizeArray(resultItems)
    const metaFieldData = {
        questionIndex: 0,
        correctAnswerCount: 0,
        questionsAnswered: [],
    }

    return { displayData, metaFieldData }
}
