import {
    SET_STORY_DATA,
    GET_STORIES_DATA,
    SET_CHILD_DATA
} from '../constants'

export const getStories = (position, sound) => {
    return {
        type: GET_STORIES_DATA,
        position,
        sound
    }
}

export const setStory = (payload) => ({
        type: SET_STORY_DATA,
        payload
})


export const setChildData = (payload) => ({
    type: SET_CHILD_DATA,
    payload,
})

