import React, { Fragment, useState, useEffect, useContext } from 'react'
import { useLocation, useParams, Link } from 'react-router-dom'
import { getUsers } from '../../store/auth/actions'
import NotesDropdown from './notesDropdown'
import './styles.scss'
import { connect, useDispatch } from 'react-redux'
import { selectUser } from '../../store/auth/selectors'
import { S3_ROOT_URL, THERAPIST_PROFILE_FOLDER } from '../../config'
import SettingSecContainer from './SettingsSec'
import TalkieTotSettings from './talkieTotSettings'
import PrepositionSettings from '../../components/games/Preposition/settings'
import FollowingDirectionSettings from './FollowingDirectionSettings'
import Notifications from './notifications'
import CustomerSupportModal from './CustomerSupportModal'
import { useSelector } from 'react-redux'
import AvatarChild from '../common/AvatarChild/AvatarChild'
import { TimeTracker } from '../common/TimeTracker/TimeTracker'
import useOnClickOutside from '../../hooks/use-onclick-outside'
import { useRef } from 'react'
import { setGameFilter } from '../../store/gameFilter/actions'
import { AudioContext } from '../../store/AudioContext/AudioContext'
const Header = (props) => {
    const loginTypeFlag = useSelector((state) => state.auth.user)
    const [showDropDown, setShowDropDown] = useState(false)
    const [displaySettingsIcon, setDisplaySettingsIcon] = useState(true)
    const [displaySettings, setDisplaySettings] = useState(false)
    const [showNotification, setShowNotification] = useState(false)
    const [notificationCount, setNotificationCount] = useState(0)
    const [customerSupport, setCustomerSupport] = useState(false)
    const location = useLocation()
    const dispatch = useDispatch()
    const { activityGame, sessionId } = useParams()
    const { setAudioData } = useContext(AudioContext)

    const ref = useRef()
    useOnClickOutside(ref, () => setShowNotification(false))

    useEffect(() => {
        props.getUsers()
        if (
            location.pathname.includes('go-together') ||
            location.pathname.includes('fun-functional') ||
            location.pathname.includes('reading-comprehensions') ||
            location.pathname.includes('stories') ||
            location.pathname.includes('words-farm') ||
            location.pathname.includes('apraxia-farm') ||
            location.pathname.includes('social-quest') ||
            location.pathname.includes('adjective-remix') ||
            location.pathname.includes('verb-quest') ||
            location.pathname.includes('is-that-silly') ||
            location.pathname.includes('ipractice-verbs') ||
            location.pathname.includes('yes-no-barn') ||
            location.pathname.includes('text-image-match') ||
            location.pathname.includes('image-text-match') ||
            location.pathname.includes('read-and-answer') ||
            location.pathname.includes('phrase-builder') ||
            location.pathname.includes('picture-naming') ||
            location.pathname.includes('sentence-completion') ||
            location.pathname.includes('sound-windows') ||
            location.pathname.includes('picture-identification') ||
            location.pathname.includes('whquestions') ||
            location.pathname.includes('divergent-naming') ||
            location.pathname.includes('figurative') ||
            location.pathname.includes('why') ||
            location.pathname.includes('predicting') ||
            location.pathname.includes('which') ||
            location.pathname.includes('inferencing') ||
            location.pathname.includes('how') ||
            location.pathname.includes('vocabulary') ||
            location.pathname.includes('sequencing') ||
            location.pathname.includes('xray-phono') ||
            location.pathname.includes('sentence-experiment') ||
            location.pathname.includes('decoding-room') ||
            location.pathname.includes('rhyming-selection') ||
            location.pathname.includes('rhyming-match') ||
            location.pathname.includes('rhyming-identification') ||
            location.pathname.includes('find-the-picture') ||
            location.pathname.includes('which-is-not-pronoun') ||
            location.pathname.includes('pronoun-heroes') ||
            location.pathname.includes('select-the-pronoun') ||
            location.pathname.includes('bakery') ||
            location.pathname.includes('pronoun-type-guide') ||
            location.pathname.includes('what-pronoun-guide') ||
            location.pathname.includes('grammar-ski') ||
            location.pathname.includes('zoo') ||
            location.pathname.includes('grocery') ||
            location.pathname.includes('letter-names') ||
            location.pathname.includes('would-rather') ||
            location.pathname.includes('does-not-belong') ||
            location.pathname.includes('picturematching') ||
            location.pathname.includes('where-does-it-go') ||
            location.pathname.includes('beginning-sounds') ||
            location.pathname.includes('learning-about-you') ||
            location.pathname.includes('smart-oral-motor') ||
            location.pathname.includes('sorting') ||
            location.pathname.includes('category-naming') ||
            location.pathname.includes('minimal-pairs-academy') ||
            location.pathname.includes('tic-tac-toe') ||
            location.pathname.includes('wouldyourather_en') ||
            location.pathname.includes('ditm') ||
            location.pathname.includes('worddecoder')
        ) {
            setDisplaySettingsIcon(false)
        }
    }, [])

    const dropDownHandler = () => {
        setShowDropDown(!showDropDown)
        if (!showDropDown) {
            const myStudents = !!document.querySelectorAll('#home-tab')
                ? document.querySelectorAll('#home-tab')
                : []
            if (myStudents.length > 0) {
                myStudents[0].click()
            }
        }
    }
    const userRedirect = () => {
        window.location.replace(process.env.REACT_APP_PAGE_BACKEND + 'users')
    }
    const logout = () => {
        localStorage.clear()
        window.location.replace(process.env.REACT_APP_PAGE_BACKEND + 'login')
    }

    const slideToggle = () => {
        console.log('slide toggle')
        setDisplaySettings(!displaySettings)
    }

    const rendererSettings = () => {
        if (location.pathname.includes('talkie-tot')) {
            return (
                <TalkieTotSettings
                    handleClose={() => setDisplaySettings(false)}
                />
            )
        } else if (location.pathname.includes('following-directions')) {
            return (
                <FollowingDirectionSettings
                    sessionId={sessionId}
                    handleClose={() => setDisplaySettings(false)}
                />
            )
        } else if (location.pathname.includes('preposition')) {
            return (
                <PrepositionSettings
                    handleClose={() => setDisplaySettings(false)}
                />
            )
        } else {
            return (
                <SettingSecContainer
                    handleClose={() => setDisplaySettings(false)}
                />
            )
        }
    }
    const handleHomeClick = () => {
        setAudioData({ url: '', isPlaying: false })
        dispatch(setGameFilter({ value: 'all' }))
    }

    return (
        <Fragment>
            {loginTypeFlag?.role === 'therapist' ? (
                <header>
                    <div className="data">
                        <div className="logo">
                            <Link to="/">
                                <img
                                    src={'/assets/imgs/smarty-ears-logo.png'}
                                    alt="logo"
                                />
                            </Link>
                        </div>
                        <div className="links">
                            {props.therapistDetails.user ? (
                                <a>
                                    {' '}
                                    <img
                                        src={`${THERAPIST_PROFILE_FOLDER}${props.therapistDetails.user.avatar}`}
                                        alt=""
                                    />{' '}
                                    {props.therapistDetails.user.first_name}{' '}
                                    {props.therapistDetails.user.last_name}
                                </a>
                            ) : (
                                ''
                            )}
                            {location.pathname === '/' ||
                            location.pathname === '/select-activity' ? (
                                <Fragment>
                                    <a onClick={userRedirect}>
                                        <i className="fas fa-chart-bar"></i>
                                    </a>
                                    <a
                                        ref={ref}
                                        onMouseDown={() => {
                                            console.log('PROPAGATION')
                                            setShowNotification(
                                                !showNotification
                                            )
                                        }}
                                        className="notify-main"
                                    >
                                        <Notifications
                                            notificationCount={(count) =>
                                                setNotificationCount(count)
                                            }
                                            show={showNotification}
                                            handleClose={() =>
                                                setShowNotification(false)
                                            }
                                            loginTypeFlag={loginTypeFlag}
                                        />
                                        <i className="fas fa-bell"></i>{' '}
                                        {notificationCount > 0 && (
                                            <span className="notify-sub">
                                                {notificationCount}
                                            </span>
                                        )}
                                    </a>
                                    {/* <a onClick={() => setCustomerSupport(!customerSupport)}>
                                        <i className="fas fa-life-ring"></i>{' '}
                                    </a> */}
                                    <a onClick={logout}>
                                        <i className="fas fa-sign-out-alt"></i>
                                    </a>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <Link to="/" onClick={handleHomeClick}>
                                        <i className="fas fa-home"></i>
                                    </Link>
                                    {loginTypeFlag?.role === 'therapist' ? (
                                        <Link
                                            to="/select-activity"
                                            onClick={() =>
                                                setAudioData({
                                                    url: '',
                                                    isPlaying: false,
                                                })
                                            }
                                        >
                                            <i className="fas fa-sync-alt"></i>
                                        </Link>
                                    ) : (
                                        <Link
                                            to="/student-activity"
                                            onClick={() =>
                                                setAudioData({
                                                    url: '',
                                                    isPlaying: false,
                                                })
                                            }
                                        >
                                            <i className="fas fa-sync-alt"></i>
                                        </Link>
                                    )}

                                    <a
                                        onClick={dropDownHandler}
                                        id="dropdownHandler"
                                    >
                                        <i className="fas fa-file-alt"></i>
                                    </a>
                                    {/* <a onClick={() => setCustomerSupport(!customerSupport)}>
                                        <i className="fas fa-life-ring"></i>{' '}
                                    </a> */}
                                    {displaySettingsIcon && (
                                        <a onMouseDown={slideToggle}>
                                            <i className="fas fa-cog"></i>
                                        </a>
                                    )}
                                    {displaySettings && rendererSettings()}
                                </Fragment>
                            )}
                        </div>
                    </div>
                </header>
            ) : loginTypeFlag?.role === 'student' ? (
                <header className="student-header">
                    <div className="data">
                        <div className="logo">
                            <a href="javascript:void(0)">
                                <img
                                    src={`${S3_ROOT_URL}/settings/logo-new.png`}
                                    alt=""
                                />
                            </a>
                        </div>
                        <div className="links">
                            <a href="#" className="nav-link">
                                {' '}
                                Teacher Name:{' '}
                                <span>
                                    {' '}
                                    {props.therapistDetails.user.therapist}
                                </span>
                            </a>
                            <a href="#" className="nav-link">
                                {' '}
                                School Name{' '}
                                <span>
                                    {props.therapistDetails.user.school}
                                </span>
                            </a>
                            {props.therapistDetails.user ? (
                                <a
                                    className="student-avatar"
                                    href="javascript:void(0)"
                                >
                                    {' '}
                                    <AvatarChild
                                        avatar={
                                            props.therapistDetails.user.avatar
                                        }
                                    />
                                    {props.therapistDetails.user.first_name}{' '}
                                    {props.therapistDetails.user.last_name}
                                </a>
                            ) : (
                                ''
                            )}
                            {location.pathname === '/student-activity' ? (
                                ''
                            ) : (
                                <Link to="/student-activity">
                                    <i className="fas fa-sync-alt"></i>
                                </Link>
                            )}

                            <a
                                ref={ref}
                                href="javascript:void(0)"
                                onMouseDown={() => {
                                    setShowNotification(!showNotification)
                                }}
                            >
                                <Notifications
                                    style={{ right: '-1.2vw' }}
                                    notificationCount={(count) =>
                                        setNotificationCount(count)
                                    }
                                    show={showNotification}
                                    handleClose={() =>
                                        setShowNotification(false)
                                    }
                                />
                                <i className="fas fa-bell"></i>

                                {notificationCount > 0 && (
                                    <span className="badge">
                                        {notificationCount}
                                    </span>
                                )}
                            </a>
                            <a href="javascript:void(0)" onClick={logout}>
                                <i className="fas fa-sign-out-alt"></i>
                            </a>
                            {!location.pathname.includes('student-activity') &&
                                displaySettingsIcon && (
                                    <a onMouseDown={slideToggle}>
                                        <i className="fas fa-cog"></i>
                                    </a>
                                )}
                            {displaySettings && rendererSettings()}
                        </div>
                    </div>
                    <TimeTracker sessionId={sessionId} />
                </header>
            ) : (
                ''
            )}

            <CustomerSupportModal
                open={customerSupport}
                handleClose={() => setCustomerSupport(false)}
            />
            <NotesDropdown
                open={showDropDown}
                activity={activityGame}
                handleClose={() => setShowDropDown(false)}
            />
        </Fragment>
    )
}
const mapStateToProps = (state) => ({
    therapistDetails: selectUser(state),
})

const mapDispatchToProps = {
    getUsers,
}

const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(Header)

export default HeaderContainer
