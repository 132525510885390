import {
    SET_TIME_TRACKER,
} from '../constants'

export const initialState = {
    timeTrackerData: {},
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_TIME_TRACKER: {
            return {
                timeTrackerData: action.payload
            }
        }
        default:
            return state
    }
}
