import { initialState } from './reducers'

export const select = (state) => state || initialState

export const displayWrittenWords = (state, gameName) => select(state).settingSec.gameSettingsData[gameName]?.displayWrittenWord
export const transitionSound = (state, gameName) => select(state).settingSec.gameSettingsData[gameName]?.transitionSound
export const moveAutomatically = (state, gameName) => select(state).settingSec.gameSettingsData[gameName]?.moveAutomatically
export const displayAnimation = (state, gameName) => select(state).settingSec.gameSettingsData[gameName]?.displayAnimation
export const isEnglishLanguage = (state, gameName) => select(state).settingSec.gameSettingsData[gameName]?.language ? 'en': 'es'
export const selectCurrentGameSettings = (state, gameName) => select(state).settingSec.gameSettingsData[gameName]
export const selectedGameSettings = (state) => select(state).settingSec.gameSettingsData
export const selectedSettings = (state) => select(state).settingSec
