import { put, select } from 'redux-saga/effects'
import { selectGameAPIData } from '../selectors'
import * as actions from '../actions'
import { randomizeArray } from '../../../utils/util'

export function* getIsThatSillyData(payload, getGameData) {
    const { from } = payload

    let sillyState = yield select(selectGameAPIData)

    if (!sillyState) {
        sillyState = yield* getGameData(
            null,
            null,
            null,
            'IS_THAT_SILLY',
            from,
            null,
            null
        )
        yield put(actions.setGameData({ apiData: sillyState }))
    }
    const displayData = randomizeArray(JSON.parse(JSON.stringify(sillyState)))
    const metaFieldData = {
        receptiveActiveItemIndex: 0,
        expressiveActiveItemIndex: 0,
        gameType: 1,
    }
    return { displayData, metaFieldData }
}
