import { 
    GET_READING_COMPREHENSIONS_SUCCESS,
    SET_LOADING,
} from '../constants'

export const initialState = {
    readingComprehensions: [],
    childSelected: [],
    selectionsSelected: [],
    childrenWithSelection: {},
    loading: false,
}

export default function (state=initialState, action) {
    switch (action.type) {
        case GET_READING_COMPREHENSIONS_SUCCESS:
            return { ...state, readingComprehensions: action.payload }

        case SET_LOADING:
            return { ...state, loading: action.payload.loading }
            
        default:
            return state
    }
}