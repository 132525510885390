//USERS
export const GET_USERS = 'GET_USERS'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_ERROR = 'GET_USERS_ERROR'

export const GET_CHILDREN = 'GET_CHILDREN'
export const GET_CHILDREN_SUCCESS = 'GET_CHILDREN_SUCCESS'
export const GET_CHILDREN_ERROR = 'GET_CHILDREN_ERROR'

export const SET_CHILDREN_SELECTED = 'SET_CHILDREN_SELECTED'

export const SET_FLASHCARD_CHILD_SELECTED = 'SET_FLASHCARD_CHILD_SELECTED'
export const SET_FLASHCARD_SELECTIONS_SELECTED =
    'SET_FLASHCARD_SELECTIONS_SELECTED'

export const SET_FLASHCARD_CHILDREN_WITH_SELECTION =
    'SET_FLASHCARD_CHILDREN_WITH_SELECTION'

export const SET_FLASHCARD_CHILDREN_WITH_SELECTION_SUCCESS =
    'SET_FLASHCARD_CHILDREN_WITH_SELECTION_SUCCESS'

export const SET_FLASHCARD_CHILDREN_WITH_SELECTION_ERROR =
    'SET_FLASHCARD_CHILDREN_WITH_SELECTION_ERROR'

// For Games Selection SET_CHILDREN_WITH_SELECTION_ERROR
export const SET_SELECTION_SELECTED = 'SET_SELECTION_SELECTED'
export const SET_CHILD_SELECTED = 'SET_CHILD_SELECTED'
export const SET_CHILDREN_WITH_SELECTION = 'SET_CHILDREN_WITH_SELECTION'
export const SET_CHILDREN_WITH_SELECTION_SUCCESS =
    'SET_CHILDREN_WITH_SELECTION_SUCCESS'
export const SET_CHILDREN_WITH_SELECTION_ERROR =
    'SET_CHILDREN_WITH_SELECTION_ERROR'
export const SET_SELECTIONS_SELECTED = 'SET_SELECTIONS_SELECTED'
export const SET_SELECTION_SUCCESS = 'SET_SELECTION_SUCCESS'
export const SET_SELECTION_ERROR = 'SET_SELECTION_ERROR'
export const SET_CHILD_DATA = 'SET_CHILD_DATA'
export const SET_GAME_DATA = 'SET_GAME_DATA'
export const SET_STORY_DATA = 'SET_STORY_DATA'

export const GET_STORIES_DATA = 'GET_STORIES_DATA'
export const SET_LOADING = 'SET_LOADING'

// For Grades
export const GET_CHILDREN_GRADES = 'GET_CHILDREN_GRADES'
export const SET_CHILDREN_GRADES = 'SET_CHILDREN_GRADES'

// On Children Change from sidebar
export const ON_CHILDREN_CHANGE = 'ON_CHILDREN_CHANGE'

// IF GAME IS RESET
export const ON_GAME_RESET = 'ON_GAME_RESET'

// For settingSec
export const SET_GAME_SETTINGS = 'SET_GAME_SETTINGS'
export const CHANGE_GAME_SETTINGS = 'CHANGE_GAME_SETTINGS'
export const SEND_GAME_DATA = 'SEND_GAME_DATA'

// For readingComprehensions
export const GET_READING_COMPREHENSIONS = 'GET_READING_COMPREHENSIONS'
export const GET_READING_COMPREHENSIONS_SUCCESS =
    'GET_READING_COMPREHENSIONS_SUCCESS'
export const SET_LOADING_READING_COMPREHENSIONS =
    'SET_LOADING_READING_COMPREHENSIONS'

export const GET_SOUND_WINDOW_DATA = 'GET_SOUND_WINDOW_DATA'
export const SET_SOUND_WINDOW_DATA = 'SET_SOUND_WINDOW_DATA'

export const GET_WORDS_FARM_DATA = 'GET_WORDS_FARM_DATA'
export const SET_WORDS_FARM_DATA = 'SET_WORDS_FARM_DATA'

export const GET_DITM_DATA = 'GET_DITM_DATA'
export const SET_DITM_DATA = 'SET_DITM_DATA'

export const GET_SOCIAL_QUEST_DATA = 'GET_SOCIAL_QUEST_DATA'
export const SET_SOCIAL_QUEST_DATA = 'SET_SOCIAL_QUEST_DATA'

export const GET_ADJECTIVE_REMIX_DATA = 'GET_ADJECTIVE_REMIX_DATA'
export const SET_ADJECTIVE_REMIX_DATA = 'SET_ADJECTIVE_REMIX_DATA'

export const GET_PREPOSITION_DATA = 'GET_PREPOSITION_DATA'
export const SET_PREPOSITION_DATA = 'SET_PREPOSITION_DATA'
export const TOGGLE_WIDGETS = 'TOGGLE_WIDGETS'
export const SET_SETTINGS = 'SET_SETTINGS'

export const GET_ALL_GAME = 'GET_ALL_GAME'
export const GET_ALL_GAME_SUCCESS = 'GET_ALL_GAME_SUCCESS'
export const GET_ALL_GAME_ERROR = 'GET_ALL_GAME_ERROR'

export const GET_GAME_CATEGORIES = 'GET_GAME_CATEGORIES'
export const GET_GAME_CATEGORIES_SUCCESS = 'GET_GAME_CATEGORIES_SUCCESS'
export const GET_GAME_CATEGORIES_ERROR = 'GET_GAME_CATEGORIES_ERROR'

export const GET_GAME_LANGUAGES = 'GET_GAME_LANGUAGES'
export const GET_GAME_LANGUAGES_SUCCESS = 'GET_GAME_LANGUAGES_SUCCESS'
export const GET_GAME_LANGUAGES_ERROR = 'GET_GAME_LANGUAGES_ERROR'
export const SET_TIME_TRACKER = 'SET_TIME_TRACKER'
export const GET_TIME_TRACKER = 'GET_TIME_TRACKER'
export const SET_GAME_FILTER = 'SET_GAME_FILTER'
export const GET_GAME_FILTER = 'GET_GAME_FILTER'
export const RESET_GAME_SETTINGS = 'RESET_GAME_SETTINGS'
