import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as actions from './actions'
import * as constants from '../constants'
import network from '../../utils/network'
import { getEndpointURL } from '../../utils/endpoint'
import { serializeQuery } from '../../utils/util'
import GET_API_KEY from "../../utils/getApiKey"

function* getData({ payload }) {
    try {
        yield put(actions.setLoading({ loading: true }))
        const action = getEndpointURL('READING_COMPREHENSIONS')
        const API_KEY = GET_API_KEY()

        const params = {
            headers: {
                Authorization: 'Bearer ' + API_KEY,
            },
        }

        let bodyPost = {
            level: payload,
        }

        if (payload === 'All') {
            bodyPost = {
                level: 'all'
            }
        }

        const queryData = yield call(serializeQuery, bodyPost)
        const { body } = yield call(network.getData, action, params, queryData)

        yield put(actions.getReadingComprehensionsSuccess(body))
    } catch (error) {
        if (error.message.indexOf("401") >= 0) {
            localStorage.clear()
            window.location.replace(process.env.REACT_APP_PAGE_BACKEND + 'login')
        }
        yield put(actions.setLoading({ loading: false }))
    }
}

export default function* () {
    yield all([
        takeEvery(constants.GET_READING_COMPREHENSIONS, getData),
    ])
}