import axios from 'axios'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { BASE_URL } from '../../../utils/environment'
import GET_API_KEY from '../../../utils/getApiKey'
import AvatarChild from '../AvatarChild/AvatarChild'
import './style.scss'

export const WellDonePopup = ({setShowWellDonePopup}) => {
    const { childSelected } = useSelector((state) => state.gamesSelection)
    const { timeTrackerData } = useSelector((state) => state.timeTracker)
    const history = useHistory()
    const closeModal = () => {
        history.push(`/student-activity`)
        setShowWellDonePopup(false)
    }
    const handleTellMyTeacherClick = () => {
        try {
            const API_KEY = GET_API_KEY()
            const params = {
                headers: {
                    Authorization: 'Bearer ' + API_KEY,
                },
            }
            const studentId = childSelected.studentId
            const url = BASE_URL + 'home-practice/notify-done'
            axios
                .post(url, { student_id: studentId }, params)
                .then((response) => {
                    history.push(`/student-activity`)
                    setShowWellDonePopup(false)
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (err) {
            console.log(err)
        }
    }
    return (
        <>
             
                <div className="modal well-done-popup">
                    <div className="wrapper">
                        <div className="user-1">
                            <AvatarChild avatar={childSelected?.avatar} />
                            <span>{childSelected?.studentName}</span>
                        </div>
                        <img
                            className="well-done-image"
                            src="/assets/images/well-done-popup/Welldone Popup.png"
                        />
                        <img
                            onClick={closeModal}
                            className="close-icon"
                            src="/assets/images/well-done-popup/Close Icon.png"
                        />
                        <span className="duration">Duration</span>
                        <div className="progress-outer">
                            <div className="progress">
                                <div
                                    className="progress-bar"
                                    style={{
                                        width: '100%',
                                    }}
                                    role="progressbar"
                                    aria-valuenow="75"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            <div className="progress-label">
                                <h6> 0 min </h6>
                                <h6>
                                    {' '}
                                    {Math.floor(
                                        timeTrackerData.duration / 60
                                    )}{' '}
                                    min
                                </h6>
                            </div>
                        </div>
                        <div
                            className="tell-my-teacher"
                            onClick={handleTellMyTeacherClick}
                        >
                            <span>TELL MY TEACHER</span>
                        </div>
                    </div>
                </div>
            
        </>
    )
}
