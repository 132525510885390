import React, { Suspense, useEffect } from 'react'
import { Route } from 'react-router'
import { BrowserRouter, Switch } from 'react-router-dom'
import { isIE, isEdge } from 'react-device-detect'
import routes from './routes'
import Layout from './components/layout'
import Loader from './components/common/loader'
import BrowserNotSupportedModal from './components/common/BrowserNotSupportedModal'
import { getAuthToken } from './utils/getAuthToken'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './styles/common-modal-style.scss'
import './styles/common-welcome-modal-style.scss'
import { AudioContextProvider } from './store/AudioContext/AudioContextProvider'
import AudioPlayer from './components/audio/audioPlayer'
import { AudioContext } from './store/AudioContext/AudioContext'
import { useContext } from 'react'

const App = () => {
    const data = useContext(AudioContext)

    useEffect(() => {
        if (
            window.location.href.includes('staging') ||
            window.location.href.includes('localhost')
        ) {
            return
        }

        // Disable right-click on the entire document
        const handleRightClick = (e) => {
            e.preventDefault()
            alert('Right-clicking is disabled.')
        }

        document.addEventListener('contextmenu', handleRightClick)

        // Cleanup function to remove event listener
        return () => {
            document.removeEventListener('contextmenu', handleRightClick)
        }
    }, [])

    if (!getAuthToken()) {
        return (
            <AudioContextProvider>
                <BrowserRouter>
                    <Layout>
                        <ToastContainer
                            position="top-right"
                            autoClose={3000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover={false}
                        />
                        {(isEdge || isIE) && (
                            <BrowserNotSupportedModal isEdge={isEdge} />
                        )}
                        <Suspense fallback={<Loader />}>
                            <Switch>
                                {routes.map((route) => (
                                    <Route
                                        key={route.path}
                                        path={route.path}
                                        exact={route.exact}
                                        component={route.component}
                                    />
                                ))}
                            </Switch>
                        </Suspense>
                    </Layout>
                    <AudioPlayer
                        // audioUrl={audioData.url}
                        // audioRefData={audioData}
                        // isPlay={audioData.isPlay}
                        isWithoutProgress={true}
                        // onAudioEnd={onAudioEnd}
                    />
                </BrowserRouter>
            </AudioContextProvider>
        )
    } else {
        window.location.replace(process.env.REACT_APP_PAGE_BACKEND)
    }
}

export default App
