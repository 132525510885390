import {
    SET_DITM_DATA,
} from '../constants'
export const initialState = {
    ditmData: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_DITM_DATA: {
            return {
                ditmState: action.payload,
            }
        }
        default:
            return state
    }
}
